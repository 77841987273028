
import { setAttribute, getAttribute, setCssVar, getCssVar } from '@/base/utils/styleUtils'
import { getDarkColor, getLightColor, } from '@/base/utils/colorUtils'
import { getBrowserLang } from '@/base/utils/browserUtils'

import { setLocale } from '@/base/locales'

// $color-primary: var(--primaryColor, #4762FE);
// $color-primary-dark: var(--primaryDarkColor, #3245D9);
// $color-primary-light: var(--primaryLightColor, #C2D1FF);
// $color-primary-lightest: var(--primaryLightestColor, #EBF0FF);
// $color-primary-transparency: var(--primaryTransparencyColor, rgba(71, 98, 254, 0.1));

// 默认主题颜色
export const DEFAULT_PRIMARY = "#009688"

// 预定义主题颜色
export const colorList = [
  DEFAULT_PRIMARY,
  "#daa96e",
  "#0c819f",
  "#409eff",
  "#27ae60",
  "#ff5c93",
  "#e74c3c",
  "#fd726d",
  "#f39c12",
  "#9b59b6"
];


export const asideTheme = {
  light: {
    "--el-aside-logo-text-color": "#303133",
    "--el-aside-border-color": "#e4e7ed"
  },
  inverted: {
    "--el-aside-logo-text-color": "#dadada",
    "--el-aside-border-color": "#414243"
  },
  dark: {
    "--el-aside-logo-text-color": "#dadada",
    "--el-aside-border-color": "#414243"
  }
};

export const headerTheme = {
  light: {
    "--el-header-logo-text-color": "#303133",
    "--el-header-bg-color": "#ffffff",
    "--el-header-text-color": "#303133",
    "--el-header-text-color-regular": "#606266",
    "--el-header-border-color": "#e4e7ed"
  },
  inverted: {
    "--el-header-logo-text-color": "#dadada",
    "--el-header-bg-color": "#191a20",
    "--el-header-text-color": "#e5eaf3",
    "--el-header-text-color-regular": "#cfd3dc",
    "--el-header-border-color": "#414243"
  },
  dark: {
    "--el-header-logo-text-color": "#dadada",
    "--el-header-bg-color": "#141414",
    "--el-header-text-color": "#e5eaf3",
    "--el-header-text-color-regular": "#cfd3dc",
    "--el-header-border-color": "#414243"
  }
};

export const menuTheme = {
  light: {
    "--el-menu-bg-color": "#ffffff",
    "--el-menu-hover-bg-color": "var(--el-color-primary-light-9)",
    "--el-menu-active-bg-color": "var(--el-color-primary-light-8)",
    "--el-menu-text-color": "#333333",
    "--el-menu-active-color": "var(--el-color-primary)",
    "--el-menu-hover-text-color": "#333333",
    "--el-menu-horizontal-sub-item-height": "50px"
  },
  inverted: {
    "--el-menu-bg-color": "#191a20",
    "--el-menu-hover-bg-color": "#000000",
    "--el-menu-active-bg-color": "#000000",
    "--el-menu-text-color": "#bdbdc0",
    "--el-menu-active-color": "#ffffff",
    "--el-menu-hover-text-color": "#ffffff",
    "--el-menu-horizontal-sub-item-height": "50px"
  },
  dark: {
    "--el-menu-bg-color": "#141414",
    "--el-menu-hover-bg-color": "#000000",
    "--el-menu-active-bg-color": "#000000",
    "--el-menu-text-color": "#bdbdc0",
    "--el-menu-active-color": "#ffffff",
    "--el-menu-hover-text-color": "#ffffff",
    "--el-menu-horizontal-sub-item-height": "50px"
  }
};

const theme = {
  namespace: 'v', //
  elNamespace: 'el', // elment-plus namespace
  layout: "classic2",// 布局模式 (纵向：vertical | 经典：classic | 横向：transverse | 分栏：columns)
  assemblySize: "default", // element 组件大小 'large' | 'default' | 'small'
  language: getBrowserLang(), // 当前系统语言
  maximize: false,// 当前页面是否全屏
  primary: DEFAULT_PRIMARY,// 主题颜色
  isDark: false, // 深色模式
  isGrey: false, // 灰色模式
  isWeak: false, // 色弱模式
  asideInverted: false, // 侧边栏反转
  headerInverted: false,// 头部反转
  isCollapse: false,// 折叠菜单
  accordion: true,// 菜单手风琴
  breadcrumb: false,// 面包屑导航
  breadcrumbIcon: true,// 面包屑导航图标
  tabs: false,// 标签页
  tabsIcon: true,// 标签页图标
  footer: false, // 页脚
  drawerVisible: false, // 主题drawer 
}

/**
 * 监听布局变化，在 body 上添加相对应的 layout class
 */
theme.setLayout = function (value) {
  const body = document.body;
  body.setAttribute("class", value);
  this.layout = value
}

/**
 * 监听当前页面是否最大化，动态添加 class
 * @param {*} value 
 */
theme.setMaximize = function (value) {
  const app = document.getElementById("app");
  if (maximize) app.classList.add("main-maximize");
  else app.classList.remove("main-maximize");
  this.maximize = value
}

/**
 * set up language
 */
theme.setLanguage = function (lang) {
  setLocale(lang)
  this.language = lang
}

/**
 * 切换暗黑模式 ==> 同时修改主题颜色、侧边栏、头部颜色
 */
theme.switchDark = function () {
  setAttribute('class', this.isDark ? 'dark' : '');

  this.changePrimary(this.primary);
  this.setAsideTheme();
  this.setHeaderTheme();
};

/**
 * 修改主题颜色
 * @param {*} value 
 */
theme.changePrimary = function (val) {
  if (!val) {
    val = this.primary
  }
  setCssVar('--el-color-primary', val)
  setCssVar('--el-color-primary-dark-2', this.isDark ? `${getLightColor(val, 0.2)}` : `${getDarkColor(val, 0.3)}`)

  for (let i = 1; i <= 9; i++) {
    const primaryColor = this.isDark ? `${getDarkColor(val, i / 10)}` : `${getLightColor(val, i / 10)}`;
    document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, primaryColor);
  }
  this.primary = val
}

/**
 * 灰色和弱色切换
 * @param {*} type 
 * @param {*} value 
 * @returns 
 */
theme.changeGreyOrWeak = function () {
  document.body.removeAttribute('style');

  if (this.isGrey) {
    document.body.setAttribute('style', 'filter: grayscale(1)')
  }
  if (this.isWeak) {
    document.body.setAttribute('style', 'filter: invert(80%)')
  }
}

/**
 * 设置菜单样式
 */
theme.setMenuTheme = function () {
  let type = 'light';
  if (this.layout === 'transverse' && this.headerInverted) type = 'inverted';
  if (this.layout !== 'transverse' && this.asideInverted) type = 'inverted';
  if (this.isDark) type = 'dark';
  const theme = menuTheme[type];
  for (const [key, value] of Object.entries(theme)) {
    document.documentElement.style.setProperty(key, value);
  }
};

// 设置侧边栏样式
theme.setAsideTheme = function () {
  let type = 'light';
  if (this.asideInverted) type = 'inverted';
  if (this.isDark) type = 'dark';
  const theme = asideTheme[type];
  for (const [key, value] of Object.entries(theme)) {
    document.documentElement.style.setProperty(key, value);
  }
  this.setMenuTheme();
};

// 设置头部样式
theme.setHeaderTheme = function () {
  let type = 'light';
  if (this.headerInverted) type = 'inverted';
  if (this.isDark) type = 'dark';
  const theme = headerTheme[type];
  for (const [key, value] of Object.entries(theme)) {
    document.documentElement.style.setProperty(key, value);
  }
  this.setMenuTheme();
};

// init theme
theme.initTheme = function () {
  this.switchDark();
  if (this.isGrey)
    this.changeGreyOrWeak('grey', true);
  if (this.isWeak)
    this.changeGreyOrWeak('weak', true);
}

export default theme