
import session from '@/base/model/session'
import { defineAsyncComponent } from 'vue'

const org = {
  orgInfo: {
    drawerVisible: false,
    currentOrg: {},
  },
  orgUser: {
    drawerVisible: false,
  },
  integration: {
    change: 0,
    drawerVisible: false,
    deleteDrawerVisible: false,
    itemData: {},
    itemInfo: {},
    itemsPanel: {
      visible: true,
    },
    itemPanel: {
      visible: false,
      item: {}
    },
  }
}

org.showOrgInfoDrawer = function(org){
  this.orgInfo.currentOrg = org
  this.orgInfo.drawerVisible = true
}

org.showIntegrationDrawer = function (itemData) {
  this.integration.drawerVisible = true
  if (itemData) {
    this.integration.itemData = itemData
    this.integration.itemInfo = this.getIntegrationItem(itemData.type)
  }
}

org.showIntegrationDeleteDrawer = function (itemData) {
  this.integration.deleteDrawerVisible = true
  if (itemData) {
    this.integration.itemData = itemData
    this.integration.itemInfo = this.getIntegrationItem(itemData.type)
  }
}

org.getIntegrationItem = function (type) {
  for (let item of this.integrationItems) {
    if (type === item.type) {
      return item
    }
  }

  return null
}

org.integrationItems = [
  {
    type: 'phone',
    label: 'Phone Number',
    tip: 'Phone Service',
    icon: 'PhoneOutlined',
    style: 'font-size: 32px;color: #477cdf!important;',
    component: defineAsyncComponent(() => import('@/platform/views/Org/Integration/Item/PhoneItem/PhoneItemDrawer.vue')),
    deleteComponent: defineAsyncComponent(() => import('@/platform/views/Org/Integration/Item/PhoneItem/DeletePhoneItemDrawer.vue')),
  },
  {
    type: 'email',
    label: 'Email Service',
    tip: 'Email Service',
    icon: 'MailOutlined',
    style: 'font-size: 32px; color:#ba3329!important;',
    component: defineAsyncComponent(() => import('@/platform/views/Org/Integration/Item/MailItem/MailItemDrawer.vue')),
    deleteComponent: defineAsyncComponent(() => import('@/platform/views/Org/Integration/Item/MailItem/DeleteMailItemDrawer.vue')),
  },
  // {
  //   type: 'facebook',
  //   label: 'Phone',
  //   tip: 'Facebook',
  //   icon: 'Location',
  //   style: 'font-size: 52px;',
  // },
  // {
  //   type: 'location',
  //   label: 'Phone',
  //   tip: 'Phone',
  //   icon: 'Location',
  //   style: 'font-size: 52px;',
  // },
  // {
  //   type: 'facebook',
  //   label: 'Facebook',
  //   tip: 'Facebook',
  //   icon: 'BrandIcon',
  //   iconName: 'facebook',
  //   iconSize: '34',
  //   style: 'font-size: 52px; color: #3a5998;',
  // },
  // {
  //   type: 'gbp',
  //   label: 'GBP',
  //   tip: 'Google business profile',
  //   icon: 'BrandIcon',
  //   iconName: 'google-my-business',
  //   iconSize: '34',
  //   style: 'font-size: 52px;  color: #477cdf;',
  // },
  // {
  //   type: 'shopify',
  //   label: 'Shopify',
  //   tip: 'Shopify',
  //   icon: 'BrandIcon',
  //   iconName: 'shopify',
  //   iconSize: '34',
  //   style: 'font-size: 52px;',
  // },
  // {
  //   type: 'gmail',
  //   label: 'Gmail',
  //   tip: 'Gmail',
  //   icon: 'BrandIcon',
  //   iconName: 'gmail',
  //   iconSize: '34',
  //   style: 'font-size: 52px;',
  // },
]

org.bussinessTypes = [
  {
    value1: 'sole_proprietorship',
    value: 'Sole Proprietorship',
    label: 'Sole Proprietorship',
  },
  {
    value1: 'partnership',
    value: 'Partnership',
    label: 'Partnership',
  },
  {
    value1: 'limited_liability_corporation',
    value: 'Limited Liability Corporation',
    label: 'Limited Liability Corporation',
  },
  {
    value1: 'co_operative',
    value: 'Co-operative',
    label: 'Co-operative',
  },
  {
    value1: 'non_profit_corporation',
    value: 'Non-profit Corporation',
    label: 'Non-profit Corporation',
  },
  {
    value1: 'corporation',
    value: 'Corporation',
    label: 'Corporation',
  },
]

org.businessIndustrys = [
  {
    value1: 'automotive',
    value: 'AUTOMOTIVE',
    label: 'AUTOMOTIVE',
  },
  {
    value1: 'agriculture',
    value: 'AGRICULTURE',
    label: 'AGRICULTURE',
  }, 
  {
    value1: 'banking',
    value: 'BANKING',
    label: 'BANKING',
  }, 
  {
    value1: 'consumer',
    value: 'CONSUMER',
    label: 'CONSUMER',
  }, {
    value1: 'construction',
    value: 'CONSTRUCTION',
    label: 'CONSTRUCTION',
  }, 
  {
    value1: 'education',
    value: 'EDUCATION',
    label: 'EDUCATION',
  }, {
    value1: 'engineering',
    value: 'ENGINEERING',
    label: 'ENGINEERING',
  }, 
  {
    value1: 'energy',
    value: 'ENERGY',
    label: 'ENERGY',
  }, {
    value1: 'oil_and_gas',
    value: 'OIL AND GAS',
    label: 'OIL AND GAS',
  }, 
  {
    value1: 'fast_moving_consumer_goods',
    value: 'FAST MOVING CONSUMER GOODS',
    label: 'FAST MOVING CONSUMER GOODS',
  }, {
    value1: 'financial',
    value: 'FINANCIAL',
    label: 'FINANCIAL',
  }, 
  {
    value1: 'fintech',
    value: 'FINTECH',
    label: 'FINTECH',
  }, 
  {
    value1: 'food_and_beverage',
    value: 'FOOD AND BEVERAGE',
    label: 'FOOD AND BEVERAGE',
  },
  {
    value1: 'government',
    value: 'GOVERNMENT',
    label: 'GOVERNMENT',
  },
  {
    value1: 'healthcare',
    value: 'HEALTHCARE',
    label: 'HEALTHCARE',
  },
  {
    value1: 'hospitality',
    value: 'HOSPITALITY',
    label: 'HOSPITALITY',
  },
  {
    value1: 'insurance',
    value: 'INSURANCE',
    label: 'INSURANCE',
  },
  {
    value1: 'legal',
    value: 'LEGAL',
    label: 'LEGAL',
  },
  {
    value1: 'manufacturing',
    value: 'MANUFACTURING',
    label: 'MANUFACTURING',
  },
  {
    value1: 'media',
    value: 'MEDIA',
    label: 'MEDIA',
  },
  {
    value1: 'online',
    value: 'ONLINE',
    label: 'ONLINE',
  },
  {
    value1: 'professional_services',
    value: 'PROFESSIONAL SERVICES',
    label: 'PROFESSIONAL SERVICES',
  },
  {
    value1: 'raw_materials',
    value: 'RAW MATERIALS',
    label: 'RAW MATERIALS',
  },
  {
    value1: 'real_estate',
    value: 'REAL ESTATE',
    label: 'REAL ESTATE',
  },
  {
    value1: 'religion',
    value: 'RELIGION',
    label: 'RELIGION',
  },
  {
    value1: 'retail',
    value: 'RETAIL',
    label: 'RETAIL',
  },
  {
    value1: 'jewelry',
    value: 'JEWELRY',
    label: 'JEWELRY',
  },
  {
    value1: 'technology',
    value: 'TECHNOLOGY',
    label: 'TECHNOLOGY',
  },
  {
    value1: 'telecommunications',
    value: 'TELECOMMUNICATIONS',
    label: 'TELECOMMUNICATIONS',
  },
  {
    value1: 'transportation',
    value: 'TRANSPORTATION',
    label: 'TRANSPORTATION',
  },
  {
    value1: 'travel',
    value: 'TRAVEL',
    label: 'TRAVEL',
  },
  {
    value1: 'electronics',
    value: 'ELECTRONICS',
    label: 'ELECTRONICS',
  },
  {
    value1: 'not_for_profit',
    value: 'NOT FOR PROFIT',
    label: 'NOT FOR PROFIT',
  },
]

org.jobPositions =[
  {
    value1: 'director',
    value: 'Director',
    label: 'Director',
  },
  {
    value1: 'gm',
    value: 'GM',
    label: 'GM',
  },
  {
    value1: 'vp',
    value: 'VP',
    label: 'VP',
  },
  {
    value1: 'ceo',
    value: 'CEO',
    label: 'CEO',
  },
  {
    value1: 'cfo',
    value: 'CFO',
    label: 'CFO',
  },
  {
    value1: 'general_counsel',
    value: 'General Counsel',
    label: 'General Counsel',
  },
  {
    value1: 'other',
    value: 'Other',
    label: 'Other',
  },
]

org.companyTypes =[
  {
    value: 'private',
    label: 'Private Company',
  },
  {
    value: 'public',
    label: 'Public company',
  },
  {
    value: 'us_non_profit',
    label: 'US Non Profit',
  },
  {
    value: 'us_government',
    label: 'US Government',
  },
]

session.org = org

export default org