import * as dictionary from './dictionary'
import { getBrowserLang  } from '@/base/utils/browserUtils'


const options = {
  title: import.meta.env.VITE_APP_TITLE, // 标题
  layout: 'classic', // layout布局
  componentSize: 'default', // 组件尺寸
  defaultLocale: dictionary.localeMap['en'], //默认语言
  locale: dictionary.localeMap[getBrowserLang()],
  prefixCls: 'v',
  elNamespace: 'el',
  visitedViews: [],
  cachedViews: [],
  selectedTag: undefined,
  mobile: false, // 是否是移动端
  rememberMe: false, //是否记住用户登录
  menuTabs:[], // 打卡的menu 列表
  switch: {
    pageLoading: false, // 路由跳转loading
    breadcrumb: true, // 面包屑
    breadcrumbIcon: true, // 面包屑图标
    collapse: false, // 折叠菜单
    uniqueOpened: false, // 是否只保持一个子菜单的展开
    hamburger: true, // 折叠图标
    screenfull: true, // 全屏图标
    size: true, // 尺寸图标
    locale: true, // 多语言图标
    tagsView: true, // 标签页
    tagsViewIcon: true, // 是否显示标签图标
    logo: true, // logo
    fixedHeader: true, // 固定toolheader
    footer: true, // 显示页脚
    greyMode: false, // 是否开始灰色模式，用于特殊悼念日
    dynamicRouter: true, // 是否动态路由
    serverDynamicRouter: true, // 是否服务端渲染动态路由
    fixedMenu: false, // 是否固定菜单
  }
}


options.setBreadcrumb = function (breadcrumb) {
  this.switch.breadcrumb = breadcrumb
}

options.setBreadcrumbIcon = function (breadcrumbIcon) {
  this.switch.breadcrumbIcon = breadcrumbIcon
}

options.setCollapse = function (collapse) {
  this.switch.collapse = collapse
}

options.setUniqueOpened = function (uniqueOpened) {
  this.switch.uniqueOpened = uniqueOpened
}

options.setHamburger = function (hamburger) {
  this.switch.hamburger = hamburger
}
options.setScreenfull = function (screenfull) {
  this.switch.screenfull = screenfull
}
options.setSize = function (size) {
  this.size = size
}
options.setLocale = function (locale) {
  this.locale = locale
}
options.setTagsView = function (tagsView) {
  this.tagsView = tagsView
}
options.setTagsViewIcon = function (tagsViewIcon) {
  this.tagsViewIcon = tagsViewIcon
}
options.setLogo = function (logo) {
  this.logo = logo
}
options.setFixedHeader = function (fixedHeader) {
  this.switch.fixedHeader = fixedHeader
}
options.setGreyMode = function (greyMode) {
  this.switch.greyMode = greyMode
}
options.setDynamicRouter = function (dynamicRouter) {
  this.dynamicRouter = dynamicRouter
}
options.setServerDynamicRouter = function (serverDynamicRouter) {
  this.serverDynamicRouter = serverDynamicRouter
}
options.setFixedMenu = function (fixedMenu) {
  this.switch.fixedMenu = fixedMenu
}
options.setPageLoading = function (pageLoading) {
  this.switch.pageLoading = pageLoading
}
options.setPageLoading = function (pageLoading) {
  this.switch.pageLoading = pageLoading
}
options.setLayout = function (layout) {
  if (this.switch.mobile && layout !== 'classic') {
    ElMessage.warning('移动端模式下不支持切换其它布局')
    return
  }
  this.layout = layout
}
options.setTitle = function (title) {
  this.title = title
}

options.setFooter = function (footer) {
  this.footer = footer
}
export default options