import { request } from '@/base/api/request'

const api = {
  listEmailServers: '/org/emailserver/list',
  getEmailServer: '/org/emailserver/get',
  addEmailServer: '/org/emailserver/add',
  updateEmailServer: '/org/emailserver/update',
  deleteEmailServer: '/org/emailserver/delete',
  testEmailServer: '/org/emailserver/test',

  listEmails: '/org/email/list',
}


//////////////////////////////////////
// Email
//////////////////////////////////////
export function listEmails(parameter, viewData) {
  return request({
    url: api.listEmails,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// EmailServer
//////////////////////////////////////
export function listEmailServers(parameter, viewData) {
  return request({
    url: api.listEmailServers,
    data: parameter,
    viewData,
  })
}

export function getEmailServer(parameter, viewData) {
  return request({
    url: api.getEmailServer,
    data: parameter,
    viewData,
  })
}


export function addEmailServer(parameter, viewData) {
  return request({
    url: api.addEmailServer,
    data: parameter,
    viewData,
  })
}

export function updateEmailServer(parameter, viewData) {
  return request({
    url: api.updateEmailServer,
    data: parameter,
    viewData,
  })
}

export function deleteEmailServer(parameter, viewData) {
  return request({
    url: api.deleteEmailServer,
    data: parameter,
    viewData,
  })
}

export function testEmailServer(parameter, viewData) {
  return request({
    url: api.testEmailServer,
    data: parameter,
    viewData,
  })
}
