export default {
  inputPassword: {
    label: 'Password',
    placeholder: 'Please input password',
    error: '密码不能为空',
    lengthError: '密码长度为 6~14 个字符',
    validateError: '密码强度较弱，请输入带有 大写字母、小写字母、数字三种字符组合的密码！',
  },
  welcome: {
    title: {
      earlyMorning: 'Good morning⛅',
      morning: 'Good morning⛅',
      noon: 'Good afternoon🌞',
      afternoon: 'Good afternoon🌞',
      evening: 'Good evening🌛',
      lateEvening: 'Good evening🌛',
    },
    message: 'Welcome to H2J2 AI',
  },
}