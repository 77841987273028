<template>
  <el-table-column v-if="column.visible != false" v-bind="column">
    <template v-if="column.slotHeader" #default="scope">
      <slot :name="column.slotHeader" v-bind="scope" />
    </template>
    <template v-if="column.slot" #default="scope">
      <slot :name="column.slot" v-bind="scope" />
    </template>
    <template v-else-if="column.tag" #default="scope">
      <el-tag :type="column.tagTypes[scope.row[column.prop]]">{{ scope.row[column.prop] }}</el-tag>
    </template>

    <template v-for="(subColumn, index) in column.children" :key="index">
      <TableColumn :column="subColumn" />
    </template>
  </el-table-column>

</template>

<script>

export default {
  components: {
  },
  props: {
    column: {
      type: Object,
      default: {}
    },
  },
  directives: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
