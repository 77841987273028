<template>



  <el-form ref="formRef" :model="formData" :rules="formRules" label-position="left" label-width="auto"
    style="max-width: 600px" require-asterisk-position="right" @keydown="viewData.error = null">

    <alert :info="viewData" />

    <el-form-item label="Email address" prop="email">
      <el-input v-model="formData.email" placeholder="Email" disabled />
    </el-form-item>
    <el-row style="width: 100%" :gutter="0">
      <el-col :span="15">
        <el-form-item label="Name" prop="firstname">
          <el-input v-model="formData.firstname" placeholder="First Name" :validate-event="viewData.validateEvent"
            style="margin-right: 4px;" />
        </el-form-item>
      </el-col>
      <el-col :span="9">
        <el-form-item label="" prop="lastname">
          <el-input v-model="formData.lastname" placeholder="Last Name" :validate-event="viewData.validateEvent"
            style="margin-left: 4px;" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="Phone number" prop="phone">
      <el-input v-model="formData.phone" @input="formatPhoneNumber" placeholder="Phone number"
        :validate-event="viewData.validateEvent" />
    </el-form-item>
    <el-form-item style="margin-top:20px">
      <el-button type="primary" :loading="viewData.loading" @click.prevent="handleUpdate">
        Update my profile</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      viewData: {
        loading: false,
        error: null,
        success: null,
        validateEvent: false,
      },
      formData: {
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
      },
      formRules: {
        email: [{ required: true, message: 'required', trigger: "blur", }],
        firstname: [{ required: true, message: 'required', trigger: "blur", }],
        lastname: [{ required: true, message: 'required', trigger: "blur", }],
        phone: [{ required: true, message: 'required', trigger: "blur", }],
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {

    handleUpdate() {
      this.viewData.validateEvent = true
      this.$refs.formRef?.validate((valid) => {
        if (!valid)
          return

        const data = {
          id: this.session.user.id,
          ...this.formData
        }
        delete data.email

        this.viewData.loading = true
        this.$api.userApi.updateInfo(data).then(response => {
          for (const [key, value] of Object.entries(this.formData)) {
            if (key != 'id') {
              this.session.user[key] = value
            }
          }

          this.$emit('updateSuccess')
          this.viewData.error = null
          this.viewData.success = 'Your profile has been successfully updated.'

        }).catch((error) => {
          this.viewData.error = error.message ?? error?.status?.text
        }).finally(() => {
          this.viewData.loading = false
        })
      })
    },
    formatPhoneNumber() {
      this.formData.phone = this.$utils.stringUtils.formatPhoneNumber(this.formData.phone)
    },
    init() {
      this.formData = {
        email: this.session.user.email,
        phone: this.session.user.phone,
        firstname: this.session.user.firstname,
        lastname: this.session.user.lastname,
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
