<template>
  <el-drawer class="org-info" v-model="org.orgInfo.drawerVisible" title="Organization" size="600px"
    @keydown="viewData.error = null" @open="handleOpen" modal-class="custom-modal">
    <template #header>
      <div class="drawer-header ">
        <el-row class="flx-center">
          <el-col :span="1">
            <el-icon size="24" style="margin-top: 6px;">
              <OfficeBuilding style="color: var(--el-color-primary)!important;" />
            </el-icon>
          </el-col>
          <el-col :span="12">
            <span class="title">Organization</span>
          </el-col>
          <el-col :span="10">
            <div style="float: right;">
              <!-- <el-button :type="viewData.submitType" :loading="viewData.loading" :icon="viewData.submitIcon"
                class="save-button" @click="handleOk"><span>{{ viewData.submitText }}</span></el-button>
              <el-button @click="handleReset">Reset</el-button> -->

              <el-button v-if="currentOrg.id != null" type="primary" :loading="viewData.loading" class="save-button"
                @click.prevent="handleUpdateOrg()"><span>Save</span></el-button>
              <el-button v-else type="primary" :loading="viewData.loading" class="save-button"
                @click.prevent="handleCreateOrg()"><span>Create</span></el-button>
              <!-- <el-button v-if="currentOrg.id != null" type="danger" class="save-button"
                @click="handleDeleteOrg"><span>Delete</span></el-button> -->
            </div>
          </el-col>
          <el-col :span="1"></el-col>
        </el-row>
      </div>
    </template>

    <el-affix :offset="70" style="width: 100%;">
      <el-alert v-if="viewData.error" :title="viewData.error" type="error" style="margin-bottom: 18px; width: 100%" />
    </el-affix>
    
    <div class="avatr flx-center">
      <UploadImage ref="avatar" :imageUrl="currentOrg?.avatar" :height="'100px'" :description="viewData.description">
      </UploadImage>
    </div>
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="left" label-width="auto"
      style="max-width: 600px">
      <el-form-item label="Name">
        <el-input v-model="formData.name" placeholder="Company name" />
      </el-form-item>
      <el-form-item label="Contact">
        <el-input v-model="formData.contact" placeholder="Contact name" />
      </el-form-item>
      <el-form-item label="Phone" prop="phone">
        <el-input v-model="formData.phone" @input="formatPhoneNumber" placeholder="Phone number" />
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="formData.email" placeholder="Email address" />
      </el-form-item>
      <el-form-item label="Address">
        <el-input v-model="formData.address" placeholder="Company physical address" clearable id="orgPlaceId" />
      </el-form-item>

      <el-divider class="divider" content-position="center"> Social accounts </el-divider>

      <el-input class="social-meida" v-model="formData.website" style="width: 100%" placeholder="website">
        <template #prefix>
          <BrandIcon name="website" shape="square" />
        </template>
      </el-input>
      <el-input class="social-meida" v-model="formData.facebook" style="width: 100%" placeholder="facebook">
        <template #prefix>
          <BrandIcon name="facebook" shape="square" />
        </template>
      </el-input>
      <el-input class="social-meida" v-model="formData.linkedin" style="width: 100%" placeholder="linkedin">
        <template #prefix>
          <BrandIcon name="linkedin" shape="square" />
        </template>
      </el-input>
      <el-input class="social-meida" v-model="formData.instagram" style="width: 100%" placeholder="instagram">
        <template #prefix>
          <BrandIcon name="instagram" shape="square" />
        </template>
      </el-input>
      <el-input class="social-meida" v-model="formData.xtwitter" style="width: 100%" placeholder="X">
        <template #prefix>
          <BrandIcon name="xtwitter" shape="square" />
        </template>
      </el-input>
      <el-input class="social-meida" v-model="formData.youtube" style="width: 100%" placeholder="youtube">
        <template #prefix>
          <BrandIcon name="youtube" shape="square" />
        </template>
      </el-input>
      <el-form-item style="margin-top:20px">
        <!-- <el-button v-if="currentOrg.id != null" type="primary" :loading="viewData.loading"
          @click.prevent="handleUpdateOrg()">Update</el-button>
        <el-button v-else type="primary" :loading="viewData.loading"
          @click.prevent="handleCreateOrg()">Create</el-button>
        <el-button v-if="currentOrg.id != null" type="danger" @click="handleDeleteOrg">Delete</el-button> -->
      </el-form-item>
    </el-form>

  </el-drawer>
</template>

<script>
import * as google from '@/base/utils/google'

export default {
  components: {
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      user: this.$session.user,
      theme: this.$session.theme,
      org: this.$session.org,
      currentOrg: {},
      viewData: {
        lastKeyPressed: -1,
        description: 'The proposed size is 350px * 180px. No bigger than 2.5mb',
        loading: false,
        error: null,
      },
      formData: {
        name: '',
        contact: '',
        phone: '',
        email: '',
        address: '',
        website: '',
        facebook: '',
        linkedin: '',
        instagram: '',
        xtwitter: '',
        youtube: '',
      },
      formRules: {
        phone: [{ required: false, message: this.$t('login.inputPhone.error'), trigger: "blur", pattern: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, }],
        email: [
          { required: false, message: this.$t('login.inputEmail.error'), trigger: "blur" },
          { type: 'email', message: this.$t('login.inputEmail.error'), trigger: ['blur'], },
        ],
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {
    handleOpen() {
      this.init()
    },
    handleUpdateOrg() {
      this.$refs.formRef?.validate((valid) => {
        if (!valid)
          return

        this.viewData.loading = true

        const data = new FormData()
        if (this.$refs.avatar.viewData.files.length > 0) {
          data.append('file', this.$refs.avatar.viewData.files[0])
        }

        Object.keys(this.formData).forEach((key) => {
          if (this.formData[key]) {
            data.append(key, this.formData[key])
          }
        })
        data.append('id', this.currentOrg.id)

        this.$api.orgApi.update(data).then(response => {

          // this.currentOrg.name = this.formData.name
          // this.currentOrg.contact = this.formData.contact
          // this.currentOrg.address = this.formData.address
          // this.currentOrg.phone = this.formData.phone
          // this.currentOrg.email = this.formData.email
          for (const [key, value] of Object.entries(this.formData)) {
            this.currentOrg[key] = value
          }

          if (response.body.avatar) {
            this.currentOrg.avatar = response.body.avatar
          }

          this.org.orgInfo.drawerVisible = false
          this.viewData.error = null
        }).catch((error) => {
          this.viewData.error = error.message ?? error?.status?.text
        }).finally(() => {
          this.viewData.loading = false
        })
      })
    },
    handleCreateOrg() {
      this.$refs.formRef?.validate((valid) => {
        if (!valid)
          return

        this.viewData.loading = true

        const data = new FormData()
        if (this.$refs.avatar.viewData.files.length > 0) {
          data.append('file', this.$refs.avatar.viewData.files[0])
        }

        Object.keys(this.formData).forEach((key) => {
          if (this.formData[key]) {
            data.append(key, this.formData[key])
          }
        })

        this.$api.orgApi.create(data).then(response => {
          const newOrg = response.body
          newOrg.role = 'owner'
          newOrg.isOwner = true
          this.switchOrg(newOrg) 

          this.org.orgInfo.drawerVisible = false
          this.viewData.error = null
        }).catch((error) => {
          this.viewData.error = error.message ?? error?.status?.text
        }).finally(() => {
          this.viewData.loading = false
        })
      })
    },
    handleDeleteOrg() {

    },
    switchOrg(org) {
      this.viewData.loading = true
      const data = {
        'org_id': org.id,
      }
      this.$api.userApi.switchOrg(data).then(response => {
        this.user.current_org = org
        this.session.jumpTo('organization')
      }).catch((error) => {
      }).finally(() => {
        this.viewData.loading = false
      })
    },
    initGoogleMap() {
      if (this.autocomplete) {
        return
      }

      this.$nextTick(() => {
        const input = document.getElementById('orgPlaceId')
        if (!input) {
          return
        }
        this.autocomplete = google.autocomplete(input, (place) => {
          this.formData.address = place.formatted_address
        })
      })
    },
    init() {
      this.$refs.avatar?.init()
      this.viewData.error = null
      this.currentOrg = this.org.orgInfo.currentOrg
      this.formData = {
        name: this.currentOrg?.name,
        contact: this.currentOrg?.contact,
        phone: this.currentOrg?.phone,
        email: this.currentOrg?.email,
        address: this.currentOrg?.address,
        website: this.currentOrg?.website,
        facebook: this.currentOrg?.facebook,
        linkedin: this.currentOrg?.linkedin,
        instagram: this.currentOrg?.instagram,
        xtwitter: this.currentOrg?.xtwitter,
        youtube: this.currentOrg?.youtube,
      }

      this.initGoogleMap()
    },

    // resetForm
    resetForm() {
      this.$refs.formRef.resetFields();
    },

    formatPhoneNumber() {
      this.formData.phone = this.$utils.stringUtils.formatPhoneNumber(this.formData.phone)
    }
  }
}
</script>

<style scoped lang="scss">
.org-info {
  .title {
    margin-left: 6px;
  }

  .save-button {
    width: 100px;

    span {
      color: var(--el-color-white) !important;
    }
  }
}

.avatr {
  margin-bottom: 18px;
}

.social-meida {
  margin: 4px 0;
}

.el-upload-dragger {
  width: 100%;
}
</style>
