
// const BasicLayout = () => import('@/base/layout/BasicLayout/index.vue')
export const constants = {
  HOME_URL: "/home/index",// 首页地址（默认）
  LOGIN_PATH: '/login', //登录路径
  NO_REDIRECT_WHITE_LIST: ['/login'], //不重定向白名单
  NO_REDIRECT_SUB_PATH_LIST: [], // 不重定向白名单的子路径
  NO_RESET_WHITE_LIST: ['Redirect', 'Login', 'NoFind', 'Root', '404', '/500'],//不重置路由白名单
}

export const extendRouterMap = []

export const constantRouterMap = [
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Root',
  //   meta: {
  //     hidden: true
  //   }
  // },
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   name: 'Redirect',
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       name: 'Redirect',
  //       component: () => import('@/base/views/Redirect/Redirect.vue'),
  //       meta: {}
  //     }
  //   ],
  //   meta: {
  //     hidden: true,
  //     noTagsView: true
  //   }
  // },
  // {
  //   path: '/login',
  //   component: () => import('@/views/Login/Login.vue'),
  //   name: 'Login',
  //   meta: {
  //     hidden: true,
  //     title: t('router.login'),
  //     noTagsView: true
  //   }
  // },
  // {
  //   path: '/personal',
  //   component: Layout,
  //   redirect: '/personal/personal-center',
  //   name: 'Personal',
  //   meta: {
  //     title: t('router.personal'),
  //     hidden: true,
  //     canTo: true
  //   },
  //   children: [
  //     {
  //       path: 'personal-center',
  //       component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
  //       name: 'PersonalCenter',
  //       meta: {
  //         title: t('router.personalCenter'),
  //         hidden: true,
  //         canTo: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/403',
    component: () => import('@/base/views/Error/403.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '403',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/base/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
  {
    path: '/500',
    component: () => import('@/base/views/Error/500.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '500',
      noTagsView: true
    }
  }
]

/**
 * errorRouter (错误页面路由)
 */
export const errorRouter = [
  {
    path: "/403",
    name: "403",
    component: () => import("@/base/views/Error/403.vue"),
    meta: { title: "403页面" }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/base/views/Error/404.vue"),
    meta: { title: "404页面" }
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/base/views/Error/500.vue"),
    meta: { title: "500页面" }
  },
  // Resolve refresh page, route warnings
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/base/views/Error/404.vue")
  }
];


// export const asyncRouterMap = [
//   {
//     path: '/dashboard',
//     component: Layout,
//     redirect: '/dashboard/analysis',
//     name: 'Dashboard',
//     meta: {
//       title: t('router.dashboard'),
//       icon: 'ant-design:dashboard-filled',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'analysis',
//         component: () => import('@/views/Dashboard/Analysis.vue'),
//         name: 'Analysis',
//         meta: {
//           title: t('router.analysis'),
//           noCache: true,
//           affix: true
//         }
//       },
//       {
//         path: 'workplace',
//         component: () => import('@/views/Dashboard/Workplace.vue'),
//         name: 'Workplace',
//         meta: {
//           title: t('router.workplace'),
//           noCache: true
//         }
//       }
//     ]
//   },
//   {
//     path: '/external-link',
//     component: Layout,
//     meta: {},
//     name: 'ExternalLink',
//     children: [
//       {
//         path: 'https://element-plus-admin-doc.cn/',
//         name: 'DocumentLink',
//         meta: {
//           title: t('router.document'),
//           icon: 'clarity:document-solid'
//         }
//       }
//     ]
//   },
//   {
//     path: '/guide',
//     component: Layout,
//     name: 'Guide',
//     meta: {},
//     children: [
//       {
//         path: 'index',
//         component: () => import('@/views/Guide/Guide.vue'),
//         name: 'GuideDemo',
//         meta: {
//           title: t('router.guide'),
//           icon: 'cib:telegram-plane'
//         }
//       }
//     ]
//   },
//   {
//     path: '/components',
//     component: Layout,
//     name: 'ComponentsDemo',
//     meta: {
//       title: t('router.component'),
//       icon: 'bx:bxs-component',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'form',
//         component: getParentLayout(),
//         redirect: '/components/form/default-form',
//         name: 'Form',
//         meta: {
//           title: t('router.form'),
//           alwaysShow: true
//         },
//         children: [
//           {
//             path: 'default-form',
//             component: () => import('@/views/Components/Form/DefaultForm.vue'),
//             name: 'DefaultForm',
//             meta: {
//               title: t('router.defaultForm')
//             }
//           },
//           {
//             path: 'use-form',
//             component: () => import('@/views/Components/Form/UseFormDemo.vue'),
//             name: 'UseForm',
//             meta: {
//               title: 'UseForm'
//             }
//           }
//         ]
//       },
//       {
//         path: 'table',
//         component: getParentLayout(),
//         redirect: '/components/table/default-table',
//         name: 'TableDemo',
//         meta: {
//           title: t('router.table'),
//           alwaysShow: true
//         },
//         children: [
//           {
//             path: 'default-table',
//             component: () => import('@/views/Components/Table/DefaultTable.vue'),
//             name: 'DefaultTable',
//             meta: {
//               title: t('router.defaultTable')
//             }
//           },
//           {
//             path: 'use-table',
//             component: () => import('@/views/Components/Table/UseTableDemo.vue'),
//             name: 'UseTable',
//             meta: {
//               title: 'UseTable'
//             }
//           },
//           {
//             path: 'tree-table',
//             component: () => import('@/views/Components/Table/TreeTable.vue'),
//             name: 'TreeTable',
//             meta: {
//               title: t('router.treeTable')
//             }
//           },
//           {
//             path: 'table-image-preview',
//             component: () => import('@/views/Components/Table/TableImagePreview.vue'),
//             name: 'TableImagePreview',
//             meta: {
//               title: t('router.PicturePreview')
//             }
//           },
//           {
//             path: 'table-video-preview',
//             component: () => import('@/views/Components/Table/TableVideoPreview.vue'),
//             name: 'TableVideoPreview',
//             meta: {
//               title: t('router.tableVideoPreview')
//             }
//           },
//           {
//             path: 'card-table',
//             component: () => import('@/views/Components/Table/CardTable.vue'),
//             name: 'CardTable',
//             meta: {
//               title: t('router.cardTable')
//             }
//           }
//         ]
//       },
//       {
//         path: 'editor-demo',
//         component: getParentLayout(),
//         redirect: '/components/editor-demo/editor',
//         name: 'EditorDemo',
//         meta: {
//           title: t('router.editor'),
//           alwaysShow: true
//         },
//         children: [
//           {
//             path: 'editor',
//             component: () => import('@/views/Components/Editor/Editor.vue'),
//             name: 'Editor',
//             meta: {
//               title: t('router.richText')
//             }
//           },
//           {
//             path: 'json-editor',
//             component: () => import('@/views/Components/Editor/JsonEditor.vue'),
//             name: 'JsonEditor',
//             meta: {
//               title: t('router.jsonEditor')
//             }
//           }
//         ]
//       },
//       {
//         path: 'search',
//         component: () => import('@/views/Components/Search.vue'),
//         name: 'Search',
//         meta: {
//           title: t('router.search')
//         }
//       },
//       {
//         path: 'descriptions',
//         component: () => import('@/views/Components/Descriptions.vue'),
//         name: 'Descriptions',
//         meta: {
//           title: t('router.descriptions')
//         }
//       },
//       {
//         path: 'image-viewer',
//         component: () => import('@/views/Components/ImageViewer.vue'),
//         name: 'ImageViewer',
//         meta: {
//           title: t('router.imageViewer')
//         }
//       },
//       {
//         path: 'dialog',
//         component: () => import('@/views/Components/Dialog.vue'),
//         name: 'Dialog',
//         meta: {
//           title: t('router.dialog')
//         }
//       },
//       {
//         path: 'icon',
//         component: () => import('@/views/Components/Icon.vue'),
//         name: 'Icon',
//         meta: {
//           title: t('router.icon')
//         }
//       },
//       {
//         path: 'icon-picker',
//         component: () => import('@/views/Components/IconPicker.vue'),
//         name: 'IconPicker',
//         meta: {
//           title: t('router.iconPicker')
//         }
//       },
//       {
//         path: 'echart',
//         component: () => import('@/views/Components/Echart.vue'),
//         name: 'Echart',
//         meta: {
//           title: t('router.echart')
//         }
//       },
//       {
//         path: 'count-to',
//         component: () => import('@/views/Components/CountTo.vue'),
//         name: 'CountTo',
//         meta: {
//           title: t('router.countTo')
//         }
//       },
//       {
//         path: 'qrcode',
//         component: () => import('@/views/Components/Qrcode.vue'),
//         name: 'Qrcode',
//         meta: {
//           title: t('router.qrcode')
//         }
//       },
//       {
//         path: 'highlight',
//         component: () => import('@/views/Components/Highlight.vue'),
//         name: 'Highlight',
//         meta: {
//           title: t('router.highlight')
//         }
//       },
//       {
//         path: 'infotip',
//         component: () => import('@/views/Components/Infotip.vue'),
//         name: 'Infotip',
//         meta: {
//           title: t('router.infotip')
//         }
//       },
//       {
//         path: 'input-password',
//         component: () => import('@/views/Components/InputPassword.vue'),
//         name: 'InputPassword',
//         meta: {
//           title: t('router.inputPassword')
//         }
//       },
//       {
//         path: 'waterfall',
//         component: () => import('@/views/Components/Waterfall.vue'),
//         name: 'waterfall',
//         meta: {
//           title: t('router.waterfall')
//         }
//       },
//       {
//         path: 'image-cropping',
//         component: () => import('@/views/Components/ImageCropping.vue'),
//         name: 'ImageCropping',
//         meta: {
//           title: t('router.imageCropping')
//         }
//       },
//       {
//         path: 'video-player',
//         component: () => import('@/views/Components/VideoPlayer.vue'),
//         name: 'VideoPlayer',
//         meta: {
//           title: t('router.videoPlayer')
//         }
//       },
//       {
//         path: 'avatars',
//         component: () => import('@/views/Components/Avatars.vue'),
//         name: 'Avatars',
//         meta: {
//           title: t('router.avatars')
//         }
//       },
//       {
//         path: 'i-agree',
//         component: () => import('@/views/Components/IAgree.vue'),
//         name: 'IAgree',
//         meta: {
//           title: t('router.iAgree')
//         }
//       }
//     ]
//   },
//   {
//     path: '/function',
//     component: Layout,
//     redirect: '/function/multipleTabs',
//     name: 'Function',
//     meta: {
//       title: t('router.function'),
//       icon: 'ri:function-fill',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'multiple-tabs',
//         component: () => import('@/views/Function/MultipleTabs.vue'),
//         name: 'MultipleTabs',
//         meta: {
//           title: t('router.multipleTabs')
//         }
//       },
//       {
//         path: 'multiple-tabs-demo/:id',
//         component: () => import('@/views/Function/MultipleTabsDemo.vue'),
//         name: 'MultipleTabsDemo',
//         meta: {
//           hidden: true,
//           title: t('router.details'),
//           canTo: true,
//           activeMenu: '/function/multiple-tabs'
//         }
//       },
//       {
//         path: 'request',
//         component: () => import('@/views/Function/Request.vue'),
//         name: 'Request',
//         meta: {
//           title: t('router.request')
//         }
//       },
//       {
//         path: 'test',
//         component: () => import('@/views/Function/Test.vue'),
//         name: 'Test',
//         meta: {
//           title: t('router.permission'),
//           permission: ['add', 'edit', 'delete']
//         }
//       }
//     ]
//   },
//   {
//     path: '/hooks',
//     component: Layout,
//     redirect: '/hooks/useWatermark',
//     name: 'Hooks',
//     meta: {
//       title: 'hooks',
//       icon: 'ic:outline-webhook',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'useWatermark',
//         component: () => import('@/views/hooks/useWatermark.vue'),
//         name: 'UseWatermark',
//         meta: {
//           title: 'useWatermark'
//         }
//       },
//       {
//         path: 'useTagsView',
//         component: () => import('@/views/hooks/useTagsView.vue'),
//         name: 'UseTagsView',
//         meta: {
//           title: 'useTagsView'
//         }
//       },
//       {
//         path: 'useValidator',
//         component: () => import('@/views/hooks/useValidator.vue'),
//         name: 'UseValidator',
//         meta: {
//           title: 'useValidator'
//         }
//       },
//       {
//         path: 'useCrudSchemas',
//         component: () => import('@/views/hooks/useCrudSchemas.vue'),
//         name: 'UseCrudSchemas',
//         meta: {
//           title: 'useCrudSchemas'
//         }
//       },
//       {
//         path: 'useClipboard',
//         component: () => import('@/views/hooks/useClipboard.vue'),
//         name: 'UseClipboard',
//         meta: {
//           title: 'useClipboard'
//         }
//       },
//       {
//         path: 'useNetwork',
//         component: () => import('@/views/hooks/useNetwork.vue'),
//         name: 'UseNetwork',
//         meta: {
//           title: 'useNetwork'
//         }
//       }
//     ]
//   },
//   {
//     path: '/level',
//     component: Layout,
//     redirect: '/level/menu1/menu1-1/menu1-1-1',
//     name: 'Level',
//     meta: {
//       title: t('router.level'),
//       icon: 'carbon:skill-level-advanced'
//     },
//     children: [
//       {
//         path: 'menu1',
//         name: 'Menu1',
//         component: getParentLayout(),
//         redirect: '/level/menu1/menu1-1/menu1-1-1',
//         meta: {
//           title: t('router.menu1')
//         },
//         children: [
//           {
//             path: 'menu1-1',
//             name: 'Menu11',
//             component: getParentLayout(),
//             redirect: '/level/menu1/menu1-1/menu1-1-1',
//             meta: {
//               title: t('router.menu11'),
//               alwaysShow: true
//             },
//             children: [
//               {
//                 path: 'menu1-1-1',
//                 name: 'Menu111',
//                 component: () => import('@/views/Level/Menu111.vue'),
//                 meta: {
//                   title: t('router.menu111')
//                 }
//               }
//             ]
//           },
//           {
//             path: 'menu1-2',
//             name: 'Menu12',
//             component: () => import('@/views/Level/Menu12.vue'),
//             meta: {
//               title: t('router.menu12')
//             }
//           }
//         ]
//       },
//       {
//         path: 'menu2',
//         name: 'Menu2',
//         component: () => import('@/views/Level/Menu2.vue'),
//         meta: {
//           title: t('router.menu2')
//         }
//       }
//     ]
//   },
//   {
//     path: '/example',
//     component: Layout,
//     redirect: '/example/example-dialog',
//     name: 'Example',
//     meta: {
//       title: t('router.example'),
//       icon: 'ep:management',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'example-dialog',
//         component: () => import('@/views/Example/Dialog/ExampleDialog.vue'),
//         name: 'ExampleDialog',
//         meta: {
//           title: t('router.exampleDialog')
//         }
//       },
//       {
//         path: 'example-page',
//         component: () => import('@/views/Example/Page/ExamplePage.vue'),
//         name: 'ExamplePage',
//         meta: {
//           title: t('router.examplePage')
//         }
//       },
//       {
//         path: 'example-add',
//         component: () => import('@/views/Example/Page/ExampleAdd.vue'),
//         name: 'ExampleAdd',
//         meta: {
//           title: t('router.exampleAdd'),
//           noTagsView: true,
//           noCache: true,
//           hidden: true,
//           canTo: true,
//           activeMenu: '/example/example-page'
//         }
//       },
//       {
//         path: 'example-edit',
//         component: () => import('@/views/Example/Page/ExampleEdit.vue'),
//         name: 'ExampleEdit',
//         meta: {
//           title: t('router.exampleEdit'),
//           noTagsView: true,
//           noCache: true,
//           hidden: true,
//           canTo: true,
//           activeMenu: '/example/example-page'
//         }
//       },
//       {
//         path: 'example-detail',
//         component: () => import('@/views/Example/Page/ExampleDetail.vue'),
//         name: 'ExampleDetail',
//         meta: {
//           title: t('router.exampleDetail'),
//           noTagsView: true,
//           noCache: true,
//           hidden: true,
//           canTo: true,
//           activeMenu: '/example/example-page'
//         }
//       }
//     ]
//   },
//   {
//     path: '/error',
//     component: Layout,
//     redirect: '/error/404',
//     name: 'Error',
//     meta: {
//       title: t('router.errorPage'),
//       icon: 'ci:error',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: '404-demo',
//         component: () => import('@/views/Error/404.vue'),
//         name: '404Demo',
//         meta: {
//           title: '404'
//         }
//       },
//       {
//         path: '403-demo',
//         component: () => import('@/views/Error/403.vue'),
//         name: '403Demo',
//         meta: {
//           title: '403'
//         }
//       },
//       {
//         path: '500-demo',
//         component: () => import('@/views/Error/500.vue'),
//         name: '500Demo',
//         meta: {
//           title: '500'
//         }
//       }
//     ]
//   },
//   {
//     path: '/authorization',
//     component: Layout,
//     redirect: '/authorization/user',
//     name: 'Authorization',
//     meta: {
//       title: t('router.authorization'),
//       icon: 'eos-icons:role-binding',
//       alwaysShow: true
//     },
//     children: [
//       {
//         path: 'department',
//         component: () => import('@/views/Authorization/Department/Department.vue'),
//         name: 'Department',
//         meta: {
//           title: t('router.department')
//         }
//       },
//       {
//         path: 'user',
//         component: () => import('@/views/Authorization/User/User.vue'),
//         name: 'User',
//         meta: {
//           title: t('router.user')
//         }
//       },
//       {
//         path: 'menu',
//         component: () => import('@/views/Authorization/Menu/Menu.vue'),
//         name: 'Menu',
//         meta: {
//           title: t('router.menuManagement')
//         }
//       },
//       {
//         path: 'role',
//         component: () => import('@/views/Authorization/Role/Role.vue'),
//         name: 'Role',
//         meta: {
//           title: t('router.role')
//         }
//       }
//     ]
//   }
// ]

export const allRouterMap = () => {
  return [
    ...constantRouterMap, ...extendRouterMap
  ]
} 

/**
 * @description 使用递归扁平化菜单，方便添加动态路由
 * @param {Array} menuList 菜单列表
 * @returns {Array}
 */
export const getFlatMenuList = function (menuList=extendRouterMap){
  // let newMenuList= JSON.parse(JSON.stringify(menuList));
  let flatMenuList =  menuList.flatMap(item => [item, ...(item.children ? getFlatMenuList(item.children) : [])]);
  return flatMenuList
}


/**
 * @description 使用递归过滤出需要渲染在左侧菜单的列表 (需剔除 hidden == true 的菜单)
 * @param {Array} menuList 菜单列表
 * @returns {Array}
 * */
export const getShowMenuList = function (menuList=extendRouterMap) {
  // let newMenuList = JSON.parse(JSON.stringify(menuList));
  let newMenuList = []
  for (let item of menuList) {
    if (!item.meta?.title) {
      newMenuList = newMenuList.concat(item.children)
    } else if (!item.meta?.hidden){
      newMenuList.push(item)
    }
  }

  return newMenuList.filter(item => {
    item.children?.length && (item.children = getShowMenuList(item.children));
    return item.meta && item.meta.title && !item.meta?.hidden;
  });
}

/**
 * @description 使用递归找出所有面包屑存储到 pinia/vuex 中
 * @param {Array} menuList 菜单列表
 * @param {Array} parent 父级菜单
 * @param {Object} result 处理后的结果
 * @returns {Object}
 */
export const getAllBreadcrumbList = function (menuList=extendRouterMap, parent=[], result = {}) {
  for (const item of menuList) {
    result[item.path] = [...parent, item];
    if (item.children)
      getAllBreadcrumbList(item.children, result[item.path], result);
  }
  return result;
}

/**
 * @description 使用递归处理路由菜单 path，生成一维数组 (第一版本地路由鉴权会用到，该函数暂未使用)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} menuPathArr 菜单地址的一维数组 ['**','**']
 * @returns {Array}
 */
export const getMenuListPath = function (menuList=extendRouterMap, menuPathArr) {
  for (const item of menuList) {
    if (typeof item === "object" && item.path) menuPathArr.push(item.path);
    if (item.children?.length) getMenuListPath(item.children, menuPathArr);
  }
  return menuPathArr;
}

/**
 * @description 递归查询当前 path 所对应的菜单对象 (该函数暂未使用)
 * @param {Array} menuList 菜单列表
 * @param {String} path 当前访问地址
 * @returns {Object | null}
 */
export const findMenuByPath = function (menuList=extendRouterMap, path) {
  for (const item of menuList) {
    if (item.path === path) return item;
    if (item.children) {
      const res = findMenuByPath(item.children, path);
      if (res) return res;
    }
  }
  return null;
}

/**
 * @description 使用递归过滤需要缓存的菜单 name (该函数暂未使用)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} keepAliveNameArr 缓存的菜单 name ['**','**']
 * @returns {Array}
 * */
export const getKeepAliveRouterName = function (menuList=extendRouterMap, keepAliveNameArr) {
  menuList.forEach(item => {
    item.meta.keepAlive && item.name && keepAliveNameArr.push(item.name);
    item.children?.length && getKeepAliveRouterName(item.children, keepAliveNameArr);
  });
  return keepAliveNameArr;
}
