<template>
  <div class="upload-box">
    <el-upload ref="uploadRef" :class="['upload', selfDisabled ? 'disabled' : '', drag ? 'no-border' : '']" action="#"
      :http-request="handleHttpUpload" :accept="fileType.join(',')" :disabled="selfDisabled" :multiple="false"
      v-model:file-list="viewData.files" :drag="drag" :show-file-list="false" :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload" :on-error="handleUploadError">

      <template v-if="viewData.imageUrl || imageUrl">
        <!-- <img v-if="viewData.imageUrl" :src="viewData.imageUrl" class="upload-image" />
        <img v-else-if="imageUrl" :src="imageUrl" class="upload-image" :fit="fit" /> -->

        <el-image v-if="viewData.imageUrl" fit="contain" :src="viewData.imageUrl" class="upload-image">
          <template #error>
            <div class="image-slot">
              <el-icon><icon-picture /></el-icon>
            </div>
          </template>
        </el-image>
        <el-image v-else-if="imageUrl" fit="contain" :src="imageUrl" class="upload-image" :fit="fit">
          <template #error>
            <div class="image-slot">
              <el-icon><icon-picture /></el-icon>
            </div>
          </template>
        </el-image>

        <div class="upload-handle" @click.stop>
          <div v-if="!selfDisabled" class="handle-icon" @click="handleEditImage">
            <el-icon>
              <Edit />
            </el-icon>
            <!-- <span>编辑</span> -->
          </div>
          <div class="handle-icon" @click="viewData.imageViewVisible = true">
            <el-icon>
              <ZoomIn />
            </el-icon>
            <!-- <span>查看</span> -->
          </div>
          <div v-if="!disabled" class="handle-icon" @click="handleDeleteImage">
            <el-icon>
              <Delete />
            </el-icon>
            <!-- <span>删除</span> -->
          </div>
        </div>
      </template>
      <template v-else-if="name">
        {{ getInitial(name) }}
      </template>
      <template v-else>
        <div class="upload-empty">
          <slot name="empty">
            <el-icon :size="20">
              <UploadFilled />
            </el-icon>
            <span>{{ description }}</span>
          </slot>
        </div>
      </template>

    </el-upload>
    <div class="error">
      <span v-if="viewData.error"> &nbsp;{{ viewData.error }}</span>
    </div>
    <el-image-viewer v-if="viewData.imageViewVisible" :url-list="[viewData.imageUrl || imageUrl]"
      @close="viewData.imageViewVisible = false" @click="alert(1); viewData.imageViewVisible = false" />
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { formContextKey, formItemContextKey } from "element-plus";

export default {
  components: {
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    drag: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileSize: {
      type: Number,
      default: 2
    },
    fileType: {
      type: Array,
      default: ["image/jpeg", "image/png", "image/gif"]
    },
    height: {
      type: String,
      default: "150px",
    },
    width: {
      type: String,
      default: "100%",
    },
    borderRadius: {
      type: String,
      default: "8px"
    },
    uploadImage: {
      type: Function,
    },
    description:{
      type: String,
      default: "Upload"
    }
  },
  directives: {
  },
  data() {
    return {
      session: this.$session,
      theme: this.$session.theme,
      viewData: {
        files: [],
        imageUrl: '',
        error: null,
        imageViewVisible: false,
      }
    }
  },
  computed: {
    selfDisabled() {
      // 获取 el-form 组件上下文
      const formContext = inject(formContextKey, 0);
      // 获取 el-form-item 组件上下文
      const formItemContext = inject(formItemContextKey, 0);

      return this.disabled || formContext?.disabled;
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {

  },
  methods: {
    handleBeforeUpload(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/svg') {
        this.viewData.error = 'Avatar picture must be JPG or PNG format!'
        return false
      } else if (rawFile.size / 1024 / 1024 > this.fileSize) {
        this.viewData.error = `Avatar picture size can not exceed ${this.fileSize}MB!`
        return false
      }
      this.files = [rawFile]
      return true
    },

    handleUploadSuccess(response, uploadFile) {
      this.viewData.imageUrl = URL.createObjectURL(uploadFile.raw)
    },

    handleUploadError() {
      this.viewData.error = this.$t('component.avatar.uploadEerror')
    },

    handleHttpUpload(options) {

      if (this.uploadImage) {
        let formData = new FormData();
        formData.append("file", options.file);
        try {
          const { data } = this.uploadImage(formData);
          emit("update:imageUrl", data.fileUrl);
        } catch (error) {
          options.onError(error);
        }
      }

      // this.$utils.imageUtils.getBase64(options.file, (base64Url) => {
      //   this.viewData.imageUrl = base64Url
      // })
      this.viewData.imageUrl = URL.createObjectURL(options.file)
      this.viewData.files = [options.file]
    },

    handleDeleteImage() {
      this.viewData.imageUrl = ''
      this.viewData.files = []
    },
    handleEditImage() {
      this.$refs.uploadRef.$el.getElementsByClassName('el-upload__input')[0].dispatchEvent(new MouseEvent("click"));
    },

    init() {
      this.viewData.imageUrl = ''
      this.viewData.files = []
    }
  }
}

</script>

<style scoped lang="scss">
.upload {
  width: v-bind(width);
  height: v-bind(height);
}

.is-error {
  .upload {

    :deep(.el-upload),
    :deep(.el-upload-dragger) {
      border: 1px dashed var(--el-color-danger) !important;

      &:hover {
        border-color: var(--el-color-primary) !important;
      }
    }
  }
}

:deep(.disabled) {

  .el-upload,
  .el-upload-dragger {
    cursor: not-allowed !important;
    background: var(--el-disabled-bg-color);
    border: 1px dashed var(--el-border-color-darker) !important;

    &:hover {
      border: 1px dashed var(--el-border-color-darker) !important;
    }
  }
}

.upload-box {
  width: v-bind(width);
  height: v-bind(height);

  .no-border {
    :deep(.el-upload) {
      border: none !important;
    }
  }

  :deep(.upload) {
    .el-upload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: v-bind(width);
      height: v-bind(height);
      overflow: hidden;
      border: 1px dashed var(--el-border-color-darker);
      border-radius: v-bind(borderRadius);
      transition: var(--el-transition-duration-fast);

      &:hover {
        border-color: var(--el-color-primary);

        .upload-handle {
          opacity: 1;
        }
      }

      .el-upload-dragger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
        border: 1px dashed var(--el-border-color-darker);
        border-radius: v-bind(borderRadius);

        &:hover {
          border: 1px dashed var(--el-color-primary);
        }
      }

      .el-upload-dragger.is-dragover {
        background-color: var(--el-color-primary-light-9);
        border: 2px dashed var(--el-color-primary) !important;
      }

      .upload-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .upload-empty {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 30px;
        color: var(--el-color-info);

        .el-icon {
          font-size: 28px;
          color: var(--el-text-color-secondary);
        }
      }

      .upload-handle {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgb(0 0 0 / 40%);
        // background-color: var(--el-color-primary-light-9);
        opacity: 0;
        transition: var(--el-transition-duration-fast);

        .handle-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          color: aliceblue;

          .el-icon {
            margin-bottom: 40%;
            font-size: 130%;
            line-height: 130%;
          }

          span {
            font-size: 85%;
            line-height: 85%;
          }
        }
      }
    }
  }

  .el-upload__tip {
    line-height: 18px;
    text-align: center;
  }
}
</style>