const keepAlive = {
  names: ['iframe',]
}


// Add this.names
keepAlive.addName = function (name) {
  !this.names.includes(name) && this.names.push(name);
}

// Remove this.names
keepAlive.removeName = function (name) {
  this.names = this.names.filter(item => item !== name);
}

// Set this.names
keepAlive.setNames = function (names) {
  this.names = names;
}

export default keepAlive