<template>
  <span :class="class" class="svg-icon" :style="style">
    <svg class="svg" :width="size" :height="size" :style="style">
      <use :xlink:href="'#' + link"></use>
    </svg>
  </span>
</template>

<script>


export default {
  components: {
  },
  props: {
    class: {
      default: null,
    },
    size: {
      type: Number,
      default: 12,
    },
    link: {
      type: String,
      default: null
    },
    color: {
      type: String,
    },
    active: {
      type: Boolean,
      default: true
    }
  },
  directives: {

  },
  data() {
    return {
    }
  },
  computed: {
    style() {
      const style = {}
      if (this.color) {
        style.fill = color
        style.color = color
      }

      if (!this.active) {
        style.fill = 'rgb(181, 193, 199)'
        style.color = 'rgb(181, 193, 199)'
        // style.backgroundColor = 'transparent'
        style.background = 'transparent'
        // style.backgroundColor = 'white'
        // style.background = 'white'
      }
      return style
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.svg-icon {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  max-width: 100%;
}

.svg-icon-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-inline-end: 10px;
  color: white;
  fill: white;
}

.svg-icon-squre {
  height: 48px;
  width: 48px;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
}

</style>
