<template>
  <el-container class="container">
    <div class="title">
      <el-avatar size="large" shape="square" :src="pageData.avatarSrc" v-if="pageData.avatarSrc"
        :style="{ backgroundColor: 'white', fontSize: '28px' }" />
      <el-avatar size="large" shape="square" :icon="pageData.avatarIcon" v-else
        :style="{ backgroundColor: pageData.avatarIconBgColor, fontSize: '28px' }" />

      <div style="margin-left: 10px;">
        <div style="vertical-align: middle; font-weight: 600; font-size: 18px;">{{ pageData.title }}</div>
        <div style="opacity: 0.8">{{ pageData.description }}</div>
      </div>
      <div class="extra">
        <slot name="extra" />
      </div>
    </div>

    <el-header height="0" style="border:0;" :style="{ display: pageData.tabs ? '' : 'none' }">
      <el-tabs v-model="pageData.activeTab" style="width:100%;">
        <template v-for="tab in pageData.tabs">
          <el-tab-pane :name="tab.name" v-if="tab.visible != false">
            <template #label>
              <span class="label">
                <span class="label-name">{{ tab.label }}</span>
              </span>
            </template>
          </el-tab-pane>
        </template>
      </el-tabs>
    </el-header>
    <el-main style="margin:-20px; overflow-x: hidden; overflow-y: auto;">
      <slot name="main" />
    </el-main>
  </el-container>
</template>


<script>

export default {
  components: {
  },
  props: {
    pageData: {
      type: Object,//'avatarIcon', 'avatarIconBgColor', 'tilte', 'description', 'tabs', 'activeTab'
      default: {}
    }
  },
  directives: {
  },
  data() {
    return {
      // viewData: {
      //   activeTab: '',
      // },
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // this.viewData.activeTab = this.activeTab
    }
  }

}
</script>

<style scoped lang="scss">
.container {
  height: calc(100% - 40px);
  margin: 20px;

  .title {
    display: flex;
    margin-bottom: 10px;
    position: relative;

    .name {
      color: var(--el-color-primary);
      font-weight: 600;
    }

    .description {
      margin-right: 20px;
      color: #8c8a9a;
      font-size: 0.9rem;
      text-overflow: ellipsis;
      overflow: hidden;
      // white-space: nowrap;
      -webkit-line-clamp: 1;
      /* 这里是超出几行省略 */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;
    }

    .extra {
      // float: right;
      position: absolute;
      right: 0px;
      top: 0;
    }
  }

  .label {
    display: flex;
    margin-left: 10px;

    .label-icon {
      font-size: 18px;
      margin-right: 4px;
      margin-top: 6px;
    }

    .label-name {
      margin-left: 4px;
    }
  }

  .el-main {
    // margin: 0px;
  }
}
</style>
