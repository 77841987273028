import axios from 'axios'
import session, { ACCESS_TOKEN } from '@/base/model/session'


/**
 *  创建 axios 实例
 */
const service = axios.create({
  // baseURL: import.meta.env.VUE_APP_API_BASE_URL,// API 请求的默认前缀
  baseURL: '/api',
  timeout: 60000, // 请求超时时间
  withCredentials: true
})

/**
 * 异常和错误处理器
 * @param  error 
 * @returns 
 */
export const errorHandler = {
  httpErrorHandle: (error) => { },
  responseErrorHandle: (response) => { }
}

// const errorHandler = (error) => {
//   if (error.response) {
//     errorHandler.httpErrorHandle(error)
//     // const data = error.response.data
//     // 从 localstorage 获取 token
//     // const token = storage.get(ACCESS_TOKEN)
//     // if (error.response.status === 403) {
//     //   notification.error({
//     //     message: 'Forbidden',
//     //     description: data.message
//     //   })
//     // }
//     // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
//     //   notification.error({
//     //     message: 'Unauthorized',
//     //     description: 'Authorization verification failed'
//     //   })
//     //   if (token) {
//     //     store.dispatch('Logout').then(() => {
//     //       setTimeout(() => {
//     //         window.location.reload()
//     //       }, 1500)
//     //     })
//     //   }
//   }
// }
// return Promise.reject(error)
// }

/**
 * service interceptor
 */
service.interceptors.request.use(config => {
  const token = session.getAccessToken()
  // 如果 token 存在, 让每个请求携带自定义 token
  if (token) {
    config.headers[ACCESS_TOKEN] = token
  }

  //print log
  if (window._DEBUG == true) {
    console.log("[Request]: " + config.url + " -> " + stringify(config.data));
  }
  //end... 

  return config
}, (error) => {
  console.log(error)
  errorHandler.httpErrorHandle(error)
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  //print log
  if (window._DEBUG == true) {
    console.log("[Response]: " + response.config.url + " -> " + (response.data ? JSON.stringify(response.data) : ""));
  }
  //end... 

  // download file
  const disposition = response.headers['content-disposition']
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      const fileName = matches[1].replace(/['"]/g, '')
      const data = response.data
      return { fileName, data }
    }
  }

  return response.data
}, (error) => {
  console.log(error)
  errorHandler.httpErrorHandle(error)
  return Promise.reject(error)
})

export function request(parameter) {
  if (parameter.viewData) {
    parameter.viewData.error = null
    parameter.viewData.loading = true
  }

  return new Promise((resolve, reject) => {
    parameter.data = parameter.data ?? {}
    parameter.method = parameter.method ?? 'post'

    service(parameter).then(response => {
      // get access_token
      if (response?.header && response?.header[ACCESS_TOKEN]) {
        session.setAccessToken(response.header[ACCESS_TOKEN])
      }

      // failed
      const statusCode = response?.status?.code
      if (statusCode && statusCode !== '0000') {
        if (parameter.viewData && statusCode != '1000' && statusCode != '1001' && statusCode != 'user_not_login_error') {
          parameter.viewData.error = response?.status?.text
          parameter.viewData.loading = false
        }

        errorHandler.responseErrorHandle(response)
        reject(response)
      } else {
        resolve(response)
      }
    }).catch(error => {
      if (parameter.viewData) {
        parameter.viewData.error = error.message ?? error?.status?.text
        parameter.viewData.loading = false
      }

      reject(error)
    }).finally(() => {
      if (parameter.viewData) {
        parameter.viewData.loading = false
      }
    })
  })
}

export async function asyncRequest(parameter) {
  try {
    parameter.data = parameter.data ?? {}
    parameter.method = parameter.method ?? 'post'
    let res = await service(parameter)
    return res
  } catch (error) {
    errorHandler.responseErrorHandle(error)
  }
}
