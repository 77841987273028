import { request } from '@/base/api/request'

const api = {
  listBots: '/bot/list',
  updatePlan: '/bot/plan/update',
  cancelPlan: '/bot/plan/cancel',
}
export function updatePlan(parameter) {
  return request({
    url: api.updatePlan,
    data: parameter
  })
}

export function cancelPlan(parameter) {
  return request({
    url: api.cancelPlan,
    data: parameter
  })
}

export function listBots(parameter) {
  return request({
    url: api.listBots,
    data: parameter
  })
}