export default {
  tabEmail: 'Login with email',
  tabPhone: 'Phone number',
  tabRegister: 'Create your account. No credit card needed.',
  tabResetPassword: 'Reset password',
  tabForgetPassword: 'Password reset request',
  tabCreatePassword: 'Create a password',
  tabResetPasswordRequest: 'Password reset request',
  inputEmail: {
    label: 'Email',
    placeholder: 'Please input email',
    error: 'Please enter a valid email address',
  },
  inputPassword: {
    label: 'Password',
    placeholder: 'Please input password',
    error: 'Please enter your password',
    lengthError: 'The password length is between 8 and 14 characters.',
    validateError: 'Create a password with uppercase, lowercase letters, and numbers.',
  },
  inputPhone: {
    label: 'Mobile number',
    placeholder: 'Mobile number',
    error: 'Please enter a valid phone number',
  },
  inputCode: {
    label: 'Verification code',
    placeholder: 'Verification code',
    error: 'Please enter the verification code',
  },
  buttonGetCode: 'Get code',
  checkRemmemberMe: {
    label: 'Remember me',
  },
  buttonLogin: 'Login',
  buttonRegister: 'Create an account',
  buttonResetPassword: 'Reset password',
  buttonCreatePassword: 'Create a password',
  buttonSendRequest: 'Send request',
  buttonBack: 'Back to login',
  checkboxIAgree: 'I agree to <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>',
  linkSignUp: 'Sign up',
  linkSignIn: 'Already have an account?',
  linkReturnSignIn: 'Reteurn to login',
  linkForgetPassword: 'Forgot your password?',
  termAndPolicy: 'By signing up, you agree to H2J2\'s <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>',
  activate: {
    waiting: 'Great! Now let\'s verify your email address.',
    success: 'Wonderful! Your account has been successfully activated.',
    failed: ' Apologies! We couldn\'t locate your account.',
  },
  welcome: {
    title: {
      earlyMorning: 'Good morning⛅',
      morning: 'Good morning⛅',
      noon: 'Good afternoon🌞',
      afternoon: 'Good afternoon🌞',
      evening: 'Good evening🌛',
      lateEvening: 'Good evening🌛',
    },
    message: 'Welcome to H2J2',
  },
}