// import "virtual:svg-icons-register";// svg icons

import SwitchDark from "@/base/components/SwitchDark"
import UploadImage from "@/base/components/Form/UploadImage"
import Alert from "@/base/components/Alert"
import { iconSetup } from "@/base/components/Icon"
import Iframe from "@/base/components/Iframe/Index.vue"
import Table from "@/base/components/Table"
import Page from "@/base/components/Page"
import Empty from "@/base/components/Empty"

export const components = {
  SwitchDark,
  UploadImage,
  Alert,
  Iframe,
  Table,
  Page,
  Empty,
}

export const setupComponent = (app) => {
  for (const [key, component] of Object.entries(components)) {
    app.component(key, component)
  }

  iconSetup(app)
}