
import { request } from '@/base/api/request'

const api = {
  listApp: '/system/app/list',

}

export function listApp(parameter) {
  return request({
    url: api.listApp,
    data: parameter
  })
}