<template>
  <el-drawer v-model="session.accountDrawer.visible" title="My profile" size="600px" @open="handleOpen">
    <el-tabs v-model="viewData.activeTab" @tab-click="handleClick" style="width:100%">
      <el-tab-pane name="information">
        <template #label>
          <span class="label">
            <span class="label-name">Information</span>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="" name="password">
        <template #label>
          <span class="label">
            <span class="label-name">Password</span>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <PersonalInformationPanel ref="personalInformationPanelRef" v-if="viewData.activeTab == 'information'" @updateSuccess="handleUpdateSuccess"/>
    <ChangePasswrodPanel ref="changePasswrodPanelRef" v-if="viewData.activeTab == 'password'" />
  </el-drawer>
</template>

<script>
import SwitchDark from "@/base/components/SwitchDark";

import ChangePasswrodPanel from "./ChangePasswrodPanel.vue";
import PersonalInformationPanel from "./PersonalInformationPanel.vue";

export default {
  components: {
    SwitchDark,
    ChangePasswrodPanel,
    PersonalInformationPanel
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      viewData: {
        visible: this.$session.user.accountDrawer?.visible,
        activeTab: 'information',
      },
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
    handleOpen() {
      this.init()
    },
    handleUpdateSuccess(){
      // this.session.accountDrawer.visible = false
    },
    init() {
      this.$refs.personalInformationPanelRef.init()
    }
  }

}
</script>

<style scoped lang="scss"></style>
