export default {
  welcome: {
    title: {
      earlyMorning: 'Good morning⛅',
      morning: 'Good morning⛅',
      noon: 'Good afternoon🌞',
      afternoon: 'Good afternoon🌞',
      evening: 'Good evening🌛',
      lateEvening: 'Good evening🌛',
    },
    message: 'Welcome to H2J2 AI',
  },
}