
const tagsView = {
  visitedViews: [],
  cachedViews: [],
  selectedTag: ''
}

// 新增缓存和tag
tagsView.addView = function (view) {
  this.addVisitedView(view)
  this.addCachedView()
}

// 新增tag
tagsView.addVisitedView = function (view) {
  if (this.visitedViews.some((v) => v.path === view.path)) return
  if (view.meta?.noTagsView) return
  this.visitedViews.push(
    Object.assign({}, view, {
      title: view.meta?.title || 'no-name'
    })
  )
}

// 新增缓存
tagsView.addCachedView = function () {
  const cacheMap = new Set()
  for (const v of this.visitedViews) {
    const item = getRawRoute(v)
    const needCache = !item?.meta?.noCache
    if (!needCache) {
      continue
    }
    const name = item.name
    cacheMap.add(name)
  }
  if (Array.from(this.cachedViews).sort().toString() === Array.from(cacheMap).sort().toString())
    return
  this.cachedViews = cacheMap
}
// 删除某个
tagsView.delView = function (view) {
  this.delVisitedView(view)
  this.addCachedView()
}
// 删除tag
tagsView.delVisitedView = function (view) {
  for (const [i, v] of this.visitedViews.entries()) {
    if (v.path === view.path) {
      this.visitedViews.splice(i, 1)
      break
    }
  }
}
// 删除缓存
tagsView.delCachedView = function () {
  const route = router.currentRoute.value
  const index = findIndex < string > (this.getCachedViews, (v) => v === route.name)
  if (index > -1) {
    this.cachedViews.delete(this.getCachedViews[index])
  }
}
// 删除所有缓存和tag
tagsView.delAllViews = function () {
  this.delAllVisitedViews()
  this.addCachedView()
}
// 删除所有tag
tagsView.delAllVisitedViews = function () {
  const userStore = useUserStoreWithOut()

  // const affixTags = this.visitedViews.filter((tag) => tag.meta.affix)
  this.visitedViews = userStore.getUserInfo
    ? this.visitedViews.filter((tag) => tag?.meta?.affix)
    : []
}
// 删除其它
tagsView.delOthersViews = function (view) {
  this.delOthersVisitedViews(view)
  this.addCachedView()
}
// 删除其它tag
tagsView.delOthersVisitedViews = function (view) {
  this.visitedViews = this.visitedViews.filter((v) => {
    return v?.meta?.affix || v.path === view.path
  })
}
// 删除左侧
tagsView.delLeftViews = function (view) {
  const index = findIndex < RouteLocationNormalizedLoaded > (
    this.visitedViews,
    (v) => v.path === view.path
  )
  if (index > -1) {
    this.visitedViews = this.visitedViews.filter((v, i) => {
      return v?.meta?.affix || v.path === view.path || i > index
    })
    this.addCachedView()
  }
}
// 删除右侧
tagsView.delRightViews = function (view) {
  const index = findIndex < RouteLocationNormalizedLoaded > (
    this.visitedViews,
    (v) => v.path === view.path
  )
  if (index > -1) {
    this.visitedViews = this.visitedViews.filter((v, i) =>{
      return v?.meta?.affix || v.path === view.path || i < index
    })
    this.addCachedView()
  }
}
tagsView.updateVisitedView = function (view) {
  for (let v of this.visitedViews) {
    if (v.path === view.path) {
      v = Object.assign(v, view)
      break
    }
  }
}
// 设置当前选中的tag
tagsView.setSelectedTag = function (tag) {
  this.selectedTag = tag
}
tagsView.setTitle = function (title, path) {
  for (const v of this.visitedViews) {
    if (v.path === (path ?? this.selectedTag?.path)) {
      v.meta.title = title
      break
    }
  }
}

tagsView.closeAll = function (callback) {
  this.delAllViews()
  callback?.()
}

tagsView.closeLeft = function (callback) {
  this.delLeftViews(this.selectedTag)
  callback?.()
}

tagsView.closeRight = function (callback) {
  this.delRightViews(this.selectedTag)
  callback?.()
}

tagsView.closeOther = function (callback) {
  this.delOthersViews(this.selectedTag)
  callback?.()
}

tagsView.closeCurrent = function (view, callback) {
  if (view?.meta?.affix) return
  this.delView(view || currentRoute)

  callback?.()
}

tagsView.refreshPage = async function (view, callback) {
  this.delCachedView()
  const { path, query } = view || currentRoute
  await nextTick()
  replace({
    path: '/redirect' + path,
    query: query
  })
  callback?.()
}

tagsView.setTitle = function (title, path) {
  this.setTitle(title, path)
}

export default tagsView