<template>
  <el-drawer v-model="viewData.drawerVisible" title="布局设置" size="290px">
    <div class="home card">
      <img class="home-bg" src="@/assets/images/welcome.png" alt="welcome" />
    </div>
  </el-drawer>
</template>

<script>
import SwitchDark from "@/base/components/SwitchDark";
import { colorList } from "@/base/model/theme"

export default {
  components: {
    SwitchDark,
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      theme: this.$session.theme,
      colorList,
      viewData: this.$biz.userBiz.dApperance.viewData,
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 设置布局方式
    setLayout(val) {
      this.theme.layout = val
      this.theme.setAsideTheme()
    },
    changePrimary() {
      this.theme.changePrimary()
    },
    changeGreyOrWeak() {
      this.theme.changeGreyOrWeak()
    },
    setAsideTheme() {
      this.theme.setAsideTheme()
    },
    setHeaderTheme() {
      this.theme.setHeaderTheme()
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .home-bg {
    width: 70%;
    max-width: 1200px;
    margin-bottom: 20px;
  }
}
</style>
