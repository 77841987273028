export default {
  orgSetting: 'Organization',
  chatbot: 'ChatBot',
  livechat: 'LiveChat',
  contact: 'Contacts',
  marketplace: 'Marketplace',
  billing: 'Billing & payments',
  integration: 'Integration',
  marketing: 'Marketing',
  addon: 'Add-ons',
}