<template>
  <el-switch v-model="theme.isDark" inline-prompt active-icon="Sunny" inactive-icon="Moon" @change="switchDark" />
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  directives: {
  },
  data() {
    return {
      session: this.$session,
      theme: this.$session.theme,
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    switchDark() {
      this.theme.switchDark()
    },
  }
}

</script>