<template>
  <transition name="fade">
    <div v-if="info.success || info.error">
      <el-alert v-if="info.error" :title="info.error" type="error" style="margin-bottom: 18px;"
        @close="info.error = null" />
      <el-alert v-if="info.success" :title="info.success" type="success" style="margin-bottom: 18px;"
        @close="info.success = null" />
    </div>
  </transition>
</template>


<script>


export default {
  components: {
  },
  props: {
    info: {
      type: Object,
      default: {},
    }
  },
  directives: {

  },
  data() {
    return {

    }
  },
  computed: {
    timeout() {
      return this.info.alertTimeout || 4000
    }
  },
  watch: {
    'info.error'(newValue, oldValue) {
      if (this.info.error) {
        setTimeout(() => {
          this.info.error = null
        }, this.timeout)
      }
    },
    'info.success'(newValue, oldValue) {
      if (this.info.success) {
        setTimeout(() => {
          this.info.success = null
        }, this.timeout)
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss"></style>