<template>
  <el-drawer v-model="org.orgUser.drawerVisible" :title="$t('org.inviteUser.title')" size="600px" @keydown="viewData.error = null"
    @open="handleOpen" modal-class="custom-modal">
    <el-alert v-if="viewData.error" :title="viewData.error" type="error" style="margin-bottom: 18px;" />
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top" label-width="auto">
      <el-form-item :label="$t('org.inviteUser.email.label')" prop="email">
        <el-input v-model="formData.email" :placeholder="$t('org.inviteUser.email.placeholder')"/>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" :loading="viewData.loading" @click.prevent="handleInviteUser()">Invite</el-button>
      </el-form-item>
    </el-form>

  </el-drawer>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      org: this.$session.org,
      viewData: {
        loading: false,
        error: null,
      },
      formData: {
        email: '',
      },
      formRules: {
        email: [
          { required: true, message: this.$t('org.inviteUser.email.error'), trigger: "blur" },
          { type: 'email', message: this.$t('org.inviteUser.email.error'), trigger: ['blur'], },
        ],
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleOpen() {
      this.init()
    },
    handleInviteUser() {
      this.$refs.formRef?.validate((valid) => {
        if (!valid)
          return

        const data = {
          'org_id': this.session.user.current_org.id,
          'email': this.formData.email
        }

        this.viewData.loading = true
        this.$api.orgUserApi.invite(data).then(response => {
          this.org.orgUser.drawerVisible = false
          this.viewData.error = null
        }).catch((error) => {
          this.viewData.error = error.message ?? error?.status?.text
        }).finally(() => {
          this.viewData.loading = false
        })
      })
    },
    handleCreateOrg() {

    },
    handleDeleteOrg() {

    },
    init() {
      this.$refs.formRef.resetFields();
      this.viewData.error = ''
      this.formData = {
        email: null,
      }
    },
  }
}
</script>

<style scoped lang="scss">
.avatr {
  margin-bottom: 18px;
}

.el-upload-dragger {
  width: 100%;
}
</style>
