import { request } from '@/base/api/request'

const api = {
  orgUserList: '/org/users',
  userOrglist: '/user/orgs',
  invite: '/org/user/invite',
  accept: '/ext/org/user/accept',
  delete_: '/org/user/delete',

}

export function orgUserList(parameter, viewData) {
  return request({
    url: api.orgUserList,
    data: parameter,
    viewData,
  })
}

export function userOrglist(parameter, viewData) {
  return request({
    url: api.userOrglist,
    data: parameter,
    viewData,
  })
}


export function invite(parameter, viewData) {
  return request({
    url: api.invite,
    data: parameter,
    viewData,
  })
}

export function accept(parameter, viewData) {
  return request({
    url: api.accept,
    data: parameter,
    viewData,
  })
}

export function delete_(parameter, viewData) {
  return request({
    url: api.delete_,
    data: parameter,
    viewData,
  })
}

