import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'

// const dictionary = {
//   sizeMap: ['default', 'large', 'small'],

//   assemblySizeList: [
//     { label: "默认", value: "default" },
//     { label: "大型", value: "large" },
//     { label: "小型", value: "small" }
//   ],

//   languageList: [
//     { label: "简体中文", value: "zh" },
//     { label: "English", value: "en" }
//   ],
//   localeMap: {
//     'zh-cn': zhCn,
//     'en': en,
//   },
// }

export const assemblySizeList = [
  { label: "默认", value: "default" },
  { label: "大型", value: "large" },
  { label: "小型", value: "small" }
]

export const languageList = [
  { label: "English", value: "en" },
  { label: "简体中文", value: "zh-cn" },
  { label: "繁體中文", value: "zh-tw" },
]

export const localeMap = {
  'zh-cn': zhCn,
  'en': en,
}

// export default dictionary