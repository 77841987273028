
export default {

  inputText: 'Please input',
  selectText: 'Please select',
  startTimeText: 'Start time',
  endTimeText: 'End time',
  login: 'Login',
  required: 'This is required',
  loginOut: 'Login out',
  document: 'Document',
  reminder: 'Reminder',
  loginOutMessage: 'Exit the system?',
  back: 'Back',
  ok: 'OK',
  cancel: 'Cancel',
  reload: 'Reload current',
  closeTab: 'Close current',
  closeTheLeftTab: 'Close left',
  closeTheRightTab: 'Close right',
  closeOther: 'Close other',
  closeAll: 'Close all',
  prevLabel: 'Prev',
  nextLabel: 'Next',
  skipLabel: 'Jump',
  doneLabel: 'End',
  menu: 'Menu',
  menuDes: 'Menu bar rendered in routed structure',
  collapse: 'Collapse',
  collapseDes: 'Expand and zoom the menu bar',
  tagsView: 'Tags view',
  tagsViewDes: 'Used to record routing history',
  tool: 'Tool',
  toolDes: 'Used to set up custom systems',
  query: 'Query',
  reset: 'Reset',
  shrink: 'Put away',
  expand: 'Expand',
  delMessage: 'Delete the selected data?',
  delWarning: 'Warning',
  delOk: 'OK',
  delCancel: 'Cancel',
  delNoData: 'Please select the data to delete',
  delSuccess: 'Deleted successfully',
  refresh: 'Refresh',
  fullscreen: 'Fullscreen',
  size: 'Size',
  columnSetting: 'Column setting',
  lengthRange: 'The length should be between {min} and {max}',
  notSpace: 'Spaces are not allowed',
  notSpecialCharacters: 'Special characters are not allowed',
  isEqual: 'The two are not equal',
  // 列设置
  setting: 'Setting'
}