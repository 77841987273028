

export function getBrowserInfo() {
  return {
    deviceMemory: navigator.deviceMemory,
    userAgent: navigator.userAgent,
    language: navigator.language,
    geolocation: navigator.geolocation
  }
}

export function getBrowserLang() {
  let browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
  let defaultBrowserLang = "";
  if (["cn", "zh", "zh-cn"].includes(browserLang.toLowerCase())) {
    defaultBrowserLang = "zh-cn";
  } else {
    defaultBrowserLang = "en";
  }
  return defaultBrowserLang;
}

export function getJsFileParameter(fileName) {
  fileName = fileName + "?"
  var scripts = document.getElementsByTagName("script");
  for (var i = 0; i < scripts.length; i++) {
    var src = scripts[i].src;
    if (src.indexOf(fileName) !== -1) {
      let parameter = src.substring(src.lastIndexOf(fileName) + (fileName.length));
      // var array = src.split("&");
      // for (var j = 0; j < array.length; j++) {
      //   var finalObj = array[j].split("=");
      //   console.log("参数：" + finalObj[0] + "值：" + finalObj[1]);
      // }
      return parameter
    }

    return null
  }
}

export function isLocalhost(){
  return  (window.location.hostname === "localhost")
}