<template>
  <div style="display: none;">
    <svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="svg_icon_facebook" viewBox="0 0 32 32">
        <path fill="currentColor" d="M19 6h5V0h-5a7 7 0 0 0-7 7v3H8v6h4v16h6V16h5l1-6h-6V7a1 1 0 0 1 1-1Z"></path>
      </symbol>
      <symbol id="svg_icon_facebook_colored" viewBox="0 0 639.42 639.43">
        <path fill="#485a93"
          d="M553.27 0H86.16A86.16 86.16 0 0 0 0 86.16v467.1a86.16 86.16 0 0 0 86.16 86.16h230.37l.4-228.49h-59.37a14 14 0 0 1-14-14l-.29-73.66a14 14 0 0 1 14-14.06h59.26v-71.12c0-82.59 50.44-127.56 124.11-127.56h60.46a14 14 0 0 1 14 14v62.1a14 14 0 0 1-14 14H464c-40.07 0-47.83 19-47.83 47v61.61h88a14 14 0 0 1 13.91 15.65l-8.73 73.66a14 14 0 0 1-13.91 12.36h-78.85l-.4 228.5h137.08a86.16 86.16 0 0 0 86.16-86.16V86.16A86.16 86.16 0 0 0 553.27 0Zm0 0">
        </path>
      </symbol>
      <symbol id="svg_icon_linkedin" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M11.43 10.29h6.33v3.24h.09a7 7 0 0 1 6.24-3.24c6.68 0 7.91 4.16 7.91 9.56v11h-6.59V21.1c0-2.33 0-5.32-3.43-5.32s-4 2.53-4 5.15v9.93h-6.55V10.29ZM0 10.29h6.86v20.57H0V10.29Zm6.86-5.72a3.43 3.43 0 1 1-3.43-3.43 3.43 3.43 0 0 1 3.43 3.43Z">
        </path>
      </symbol>
      <symbol id="svg_icon_instagram" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M16 2.88c4.27 0 4.78 0 6.46.09a8.77 8.77 0 0 1 3 .55 5 5 0 0 1 1.84 1.19 5 5 0 0 1 1.2 1.84 8.8 8.8 0 0 1 .55 3c.08 1.69.09 2.19.09 6.46s0 4.78-.09 6.46a8.77 8.77 0 0 1-.55 3 4.94 4.94 0 0 1-1.19 1.84 5 5 0 0 1-1.84 1.2 8.81 8.81 0 0 1-3 .55c-1.69.08-2.19.09-6.46.09s-4.78 0-6.46-.09a8.77 8.77 0 0 1-3-.55 5 5 0 0 1-1.84-1.19 5 5 0 0 1-1.2-1.84 8.81 8.81 0 0 1-.55-3c-.04-1.71-.06-2.21-.06-6.48s0-4.78.09-6.46a8.77 8.77 0 0 1 .55-3 5 5 0 0 1 1.2-1.81 5 5 0 0 1 1.84-1.2 8.8 8.8 0 0 1 3-.55c1.65-.08 2.15-.1 6.42-.1ZM16 0c-4.33 0-4.88 0-6.58.1a11.68 11.68 0 0 0-3.89.74A7.8 7.8 0 0 0 2.7 2.69 7.83 7.83 0 0 0 .85 5.52 11.72 11.72 0 0 0 .1 9.4C0 11.12 0 11.66 0 16s0 4.89.1 6.6a11.68 11.68 0 0 0 .74 3.88 7.81 7.81 0 0 0 1.86 2.84 7.82 7.82 0 0 0 2.83 1.84 11.72 11.72 0 0 0 3.88.74c1.71.1 2.25.1 6.59.1s4.89 0 6.59-.09a11.68 11.68 0 0 0 3.88-.74 8.18 8.18 0 0 0 4.67-4.67 11.72 11.72 0 0 0 .74-3.88C32 20.9 32 20.35 32 16s0-4.89-.09-6.59a11.68 11.68 0 0 0-.74-3.88 7.51 7.51 0 0 0-1.83-2.84A7.82 7.82 0 0 0 26.49.85 11.72 11.72 0 0 0 22.61.1C20.9 0 20.35 0 16 0Z">
        </path>
        <path fill="currentColor"
          d="M16 7.78A8.22 8.22 0 1 0 24.23 16 8.22 8.22 0 0 0 16 7.78Zm0 13.55A5.33 5.33 0 1 1 21.34 16 5.33 5.33 0 0 1 16 21.33ZM26.47 7.46a1.92 1.92 0 1 1-1.92-1.92 1.92 1.92 0 0 1 1.92 1.92Z">
        </path>
      </symbol>
      <symbol id="svg_icon_xtwitter" viewBox="0 0 1200 1227">
        <g clip-path="url(#clip0_2842_23331)">
          <path fill="currentColor"
            d="M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z">
          </path>
        </g>
        <defs>
          <clipPath id="svg_icon_i_social_editor_xtwitter_clip0_2842_23331">
            <path fill="#fff" d="M0 0h1200v1227H0z"></path>
          </clipPath>
        </defs>
      </symbol>
      <symbol id="svg_icon_youtube" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M31.92 9.85a6.9 6.9 0 0 0-1.27-3.18 4.58 4.58 0 0 0-3.21-1.36C23 5 16.25 5 16.25 5S9.52 5 5 5.32a4.58 4.58 0 0 0-3.16 1.36A6.85 6.85 0 0 0 .57 9.85 48.52 48.52 0 0 0 .25 15v2.42a48.67 48.67 0 0 0 .32 5.18 6.94 6.94 0 0 0 1.27 3.17 5.46 5.46 0 0 0 3.53 1.37c2.56.24 10.88.32 10.88.32s6.73 0 11.2-.33a4.58 4.58 0 0 0 3.21-1.36 6.9 6.9 0 0 0 1.27-3.17 48.5 48.5 0 0 0 .32-5.18V15a49.59 49.59 0 0 0-.33-5.18Zm-19 10.55v-9l8.64 4.51Z">
        </path>
      </symbol>
      <symbol id="svg_icon_google-my-business" viewBox="0 0 20 18">
        <path fill="currentColor"
          d="M10.966 6.816V.97h3.374l.69 5.602a2 2 0 0 1-.497 1.58 2.05 2.05 0 0 1-1.527.681c-1.125 0-2.04-.905-2.04-2.017Zm-5.043-.244L6.613.97h3.375v5.846c0 1.112-.915 2.017-2.04 2.017a2.05 2.05 0 0 1-1.527-.68 2.003 2.003 0 0 1-.498-1.58Zm-4.945.205L2.697.97h2.931l-.76 6.166A1.947 1.947 0 0 1 2.93 8.833a1.944 1.944 0 0 1-1.952-1.93v-.126Zm1.2 9.832V9.706A2.945 2.945 0 0 0 5.415 8.44a3.033 3.033 0 0 0 5.062.004 3.033 3.033 0 0 0 5.062-.004 2.94 2.94 0 0 0 3.237 1.266v6.903c0 .37-.305.67-.68.67H2.858a.676.676 0 0 1-.68-.67Zm13.269-2.097h1.225c-.225.514-.742.86-1.326.86-.798 0-1.445-.64-1.445-1.43 0-.788.647-1.428 1.445-1.428.363 0 .704.133.969.37l.157-.006.65-.69-.005-.16a2.628 2.628 0 0 0-1.77-.681c-1.449 0-2.624 1.162-2.624 2.596 0 1.434 1.175 2.596 2.623 2.596 1.448 0 2.623-1.162 2.623-2.596 0-.146-.013-.293-.04-.44l-.11-.094h-2.372l-.113.113v.878l.113.112Zm.638-7.376L15.325.97h2.932l1.723 5.84-.004.094c0 1.064-.876 1.93-1.953 1.93-.986 0-1.819-.73-1.938-1.697Z">
        </path>
      </symbol>
      <symbol id="svg_icon_rss" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M4.26 23.47a4.25 4.25 0 1 0 4.27 4.25 4.27 4.27 0 0 0-4.27-4.25ZM0 10.87V17a14.86 14.86 0 0 1 10.58 4.4A14.9 14.9 0 0 1 15 32h6.16A21.15 21.15 0 0 0 0 10.87ZM0 0v6.14A25.88 25.88 0 0 1 25.85 32H32A32 32 0 0 0 0 0Z">
        </path>
      </symbol>
      <symbol id="svg_icon_yelp" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M19 20.42a1.56 1.56 0 0 0-.11 1.58l4 6.79a2.24 2.24 0 0 0 1.22.9 2.09 2.09 0 0 0 1.13-.48l3.15-4.59a2.81 2.81 0 0 0 .32-1.09 1.23 1.23 0 0 0-1.06-.92l-7.46-2.44a1.33 1.33 0 0 0-1.22.3ZM18.59 17a1.48 1.48 0 0 0 1.43.47l7.45-2.22a2.24 2.24 0 0 0 1.16-1 2.21 2.21 0 0 0-.17-1.25L24.9 8.76a1.29 1.29 0 0 0-.9-.6 1.4 1.4 0 0 0-1.18.84l-4.21 6.75a1.41 1.41 0 0 0 0 1.28Zm-3.52-2.63a1.83 1.83 0 0 0 1-1.52L16 2a2.77 2.77 0 0 0-.72-1.7 1.37 1.37 0 0 0-1.43-.19L7.67 2.46a1.92 1.92 0 0 0-.92.72C6.3 3.9 7.21 5 7.21 5l6.42 8.93a1.62 1.62 0 0 0 1.44.47Zm-1.53 4.37a1.74 1.74 0 0 0-1-1.33L5.92 14a2.14 2.14 0 0 0-1.46-.13 1.47 1.47 0 0 0-.72 1l-.44 5.39a3.29 3.29 0 0 0 .17 1.37c.34.61 1.45.18 1.45.18l7.76-1.75a1.23 1.23 0 0 0 .86-1.33Zm1.93 2.93A1.5 1.5 0 0 0 14 22l-5.18 5.88a2.27 2.27 0 0 0-.48 1.44 1.41 1.41 0 0 0 .78.95l5.22 1.68a2.69 2.69 0 0 0 1.11 0c.68-.2.55-1.29.55-1.29l.12-7.89a1.36 1.36 0 0 0-.64-1.08Z">
        </path>
      </symbol>
      <symbol id="svg_icon_whatsapp" viewBox="0 0 30 30">
        <path fill="currentColor"
          d="M11.108 8.762c.33.728 1.051 2.548 1.144 2.732.093.184.155.399.031.645-.124.245-.185.399-.37.613-.186.215-.39.48-.557.645-.187.184-.38.383-.163.751.216.369.961 1.573 2.064 2.549 1.418 1.254 2.614 1.642 2.985 1.827.371.184.588.153.804-.093.217-.245.928-1.074 1.175-1.442.248-.369.495-.307.835-.185.34.123 2.165 1.014 2.537 1.198.37.184.618.276.711.43.093.153.093.89-.216 1.75-.31.859-1.825 1.688-2.506 1.749-.68.061-1.318.305-4.454-.921-3.772-1.475-6.154-5.31-6.34-5.556-.185-.246-1.515-1.996-1.515-3.807 0-1.81.959-2.7 1.299-3.07.34-.368.742-.46.99-.46.247 0 .495 0 .71.009.265.01.558.023.836.636Zm3.871 18.215c6.805 0 12.343-5.492 12.346-12.24a12.114 12.114 0 0 0-3.613-8.66 12.318 12.318 0 0 0-8.728-3.59c-6.81 0-12.349 5.49-12.351 12.24A12.133 12.133 0 0 0 4.52 21.24l.294.463-1.248 4.517 4.673-1.215.451.265a12.41 12.41 0 0 0 6.284 1.707h.005ZM25.487 4.319c2.804 2.783 4.348 6.483 4.346 10.418-.003 8.12-6.667 14.727-14.854 14.727h-.006a14.951 14.951 0 0 1-7.099-1.793L0 29.719l2.107-7.631a14.605 14.605 0 0 1-1.982-7.363C.128 6.605 6.79 0 14.979 0c3.974.002 7.703 1.535 10.508 4.319Z">
        </path>
      </symbol>
      <symbol id="svg_icon_pinterest" viewBox="0 0 24 24">
        <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g id="pinterest" fill="currentColor" fill-rule="nonzero">
            <path id="Shape"
              d="M12 .118C5.438.118.117 5.438.117 12.001c0 5.036 3.134 9.332 7.554 11.062-.102-.938-.198-2.384.043-3.407.215-.927 1.393-5.903 1.393-5.903s-.353-.712-.353-1.762c0-1.65.96-2.888 2.148-2.888 1.012 0 1.505.76 1.505 1.671 0 1.019-.648 2.545-.986 3.954-.283 1.184.59 2.148 1.758 2.148 2.11 0 3.733-2.228 3.733-5.443 0-2.844-2.046-4.832-4.96-4.832-3.38 0-5.362 2.534-5.362 5.159 0 1.023.39 2.116.883 2.711a.35.35 0 0 1 .081.343c-.09.375-.29 1.184-.332 1.35-.054.22-.171.262-.397.161-1.483-.69-2.41-2.86-2.41-4.602 0-3.75 2.72-7.189 7.847-7.189 4.12 0 7.324 2.936 7.324 6.863 0 4.092-2.582 7.392-6.167 7.392-1.205 0-2.335-.626-2.721-1.366 0 0-.595 2.266-.74 2.824-.268 1.033-.99 2.324-1.478 3.112a11.93 11.93 0 0 0 3.52.53c6.561 0 11.882-5.319 11.882-11.882 0-6.568-5.32-11.888-11.883-11.888H12Z">
            </path>
          </g>
        </g>
      </symbol>
      <symbol id="svg_icon_waze" viewBox="0 0 27 24">
        <path fill="currentColor"
          d="M26.847 9.09c.787 4.6-1.678 8.995-6.136 11.15.705 1.826-.672 3.76-2.62 3.76-1.49-.001-2.719-1.155-2.796-2.625-.349.01-3.48 0-4.138-.034a2.755 2.755 0 0 1-.901 1.924 2.822 2.822 0 0 1-2.015.73c-1.836-.072-3.142-1.866-2.548-3.638-2.018-.702-3.933-1.868-5.401-3.793-.705-.925-.026-2.239 1.13-2.239 2.51 0 1.746-2.902 2.339-5.907C4.761 3.386 10.091 0 15.24 0c5.556 0 10.689 3.786 11.607 9.09Zm-6.976 9.996c2.277-1.027 4.41-3.038 5.22-5.471C27.287 7.02 21.615 1.4 15.24 1.4c-4.525 0-9.234 2.97-10.088 7.286-.517 2.62.27 7.037-3.728 7.037 1.352 1.773 3.163 2.819 5.082 3.43 1.337-1.167 3.463-.828 4.328.77.771.053 4.294.062 4.766.043a2.798 2.798 0 0 1 1.923-1.449c.83-.17 1.693.039 2.348.57Zm-9.13-10.775c0-1.862 2.757-1.862 2.757 0 0 1.861-2.757 1.86-2.757 0Zm6.32 0c0-1.862 2.758-1.862 2.758 0 0 1.861-2.757 1.861-2.757 0Zm-6.647 3.787c-.187-.908 1.203-1.189 1.389-.28l.003.015c.225 1.148 1.619 2.358 3.477 2.308 1.934-.05 3.212-1.19 3.475-2.292.242-.86 1.551-.555 1.381.322-.283 1.188-1.692 3.321-4.958 3.37-2.307 0-4.385-1.492-4.766-3.442l-.001-.002Z">
        </path>
      </symbol>
      <symbol id="svg_icon_vimeo" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M32 8.56q-.22 4.67-6.52 12.79-6.53 8.47-11 8.48-2.79 0-4.73-5.16-1.36-4.73-2.65-9.47Q5.67 10 4 10a10.1 10.1 0 0 0-2.51 1.51L0 9.61q2.37-2.08 4.67-4.16 3.16-2.73 4.74-2.88 3.73-.36 4.6 5.11.93 5.9 1.29 7.33 1.08 4.89 2.37 4.89 1 0 3-3.17a12.59 12.59 0 0 0 2.16-4.83c.19-1.82-.52-2.74-2.16-2.74a6 6 0 0 0-2.37.52q2.36-7.73 9-7.51 4.96.13 4.7 6.39Z">
        </path>
      </symbol>
      <symbol id="svg_icon_snapchat" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M16.31 1A8.31 8.31 0 0 1 21 2.3a8.81 8.81 0 0 1 3.2 3.77 8.75 8.75 0 0 1 .53 3.5q0 .92-.18 3.74a1.22 1.22 0 0 0 .55.14 2.9 2.9 0 0 0 1-.26 2.9 2.9 0 0 1 1-.26 2 2 0 0 1 1.1.35 1 1 0 0 1 .53.9 1.26 1.26 0 0 1-.62 1.06 4.11 4.11 0 0 1-1.35.62 4.8 4.8 0 0 0-1.35.57 1.1 1.1 0 0 0-.62.93 2.37 2.37 0 0 0 .23.84 10.38 10.38 0 0 0 2 2.94 8.42 8.42 0 0 0 2.83 2 8.84 8.84 0 0 0 1.57.45.63.63 0 0 1 .55.69q0 1.37-4.29 2a2.07 2.07 0 0 0-.21.76 2.71 2.71 0 0 1-.27.91.68.68 0 0 1-.65.36 8.84 8.84 0 0 1-1.21-.13 8.9 8.9 0 0 0-1.23-.18 6.34 6.34 0 0 0-1.21.1 3.64 3.64 0 0 0-1.23.44 10 10 0 0 0-1.14.74q-.53.4-1.14.79a5.84 5.84 0 0 1-1.49.66A6.72 6.72 0 0 1 16 31a6.48 6.48 0 0 1-1.89-.26 5.69 5.69 0 0 1-1.47-.66q-.6-.39-1.13-.79a10 10 0 0 0-1.14-.74 3.64 3.64 0 0 0-1.21-.44A6.67 6.67 0 0 0 7.89 28a7.65 7.65 0 0 0-1.28.15 7.52 7.52 0 0 1-1.15.15.71.71 0 0 1-.68-.3 2.74 2.74 0 0 1-.28-1 2.12 2.12 0 0 0-.21-.78Q0 25.62 0 24.25a.63.63 0 0 1 .55-.69 8.71 8.71 0 0 0 1.57-.45 8.31 8.31 0 0 0 2.83-2 10.21 10.21 0 0 0 2-2.94 2.37 2.37 0 0 0 .23-.84 1.1 1.1 0 0 0-.62-.93 5 5 0 0 0-1.36-.58 4.38 4.38 0 0 1-1.36-.62 1.22 1.22 0 0 1-.62-1 1.07 1.07 0 0 1 .51-.89 1.82 1.82 0 0 1 1.08-.36 3 3 0 0 1 .94.25 3 3 0 0 0 1 .25 1.38 1.38 0 0 0 .63-.14q-.14-2.77-.14-3.71a8.82 8.82 0 0 1 .53-3.52 8.3 8.3 0 0 1 3.37-3.88A10.64 10.64 0 0 1 16.31 1Z">
        </path>
      </symbol>
      <symbol id="svg_icon_reddit" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M8 18a2 2 0 1 1 2 2 2 2 0 0 1-2-2Zm12 0a2 2 0 1 1 2 2 2 2 0 0 1-2-2Zm.1 4.27a1.19 1.19 0 1 1 1.47 1.87A9.53 9.53 0 0 1 16 26a9.53 9.53 0 0 1-5.57-1.86 1.19 1.19 0 1 1 1.47-1.87 7.21 7.21 0 0 0 4.1 1.35 7.21 7.21 0 0 0 4.1-1.35ZM32 14a4 4 0 0 0-7.49-1.94 17.49 17.49 0 0 0-7.29-2l2.38-5.38L24.17 6a3 3 0 1 0 .19-2.42l-5.09-1.47a1.19 1.19 0 0 0-1.41.66L14.61 10a17.44 17.44 0 0 0-7.11 2 4 4 0 1 0-5.11 5.6A7.33 7.33 0 0 0 2 20c0 5.52 6.27 10 14 10s14-4.48 14-10a7.3 7.3 0 0 0-.39-2.34A4 4 0 0 0 32 14ZM27 3.87A1.13 1.13 0 1 1 25.87 5 1.13 1.13 0 0 1 27 3.87ZM2 14a2 2 0 0 1 3.81-.85 10.61 10.61 0 0 0-2.55 2.71A2 2 0 0 1 2 14Zm14 13.62c-6.42 0-11.63-3.41-11.63-7.62S9.58 12.37 16 12.37 27.62 15.79 27.62 20s-5.2 7.62-11.62 7.62Zm12.74-11.76a10.62 10.62 0 0 0-2.55-2.71 2 2 0 1 1 2.55 2.71Z">
        </path>
      </symbol>
      <symbol id="svg_icon_tripadvisor" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M9 17.4a1.26 1.26 0 0 1-.38.92 1.24 1.24 0 0 1-.91.38 1.3 1.3 0 0 1-1.27-1.3 1.24 1.24 0 0 1 .38-.91 1.26 1.26 0 0 1 .92-.38A1.3 1.3 0 0 1 9 17.4Zm16 0a1.31 1.31 0 1 1-2.61 0 1.23 1.23 0 0 1 .38-.92 1.33 1.33 0 0 1 1.85 0 1.24 1.24 0 0 1 .45.91Zm-14.44 0a2.69 2.69 0 0 0-2.68-2.68A2.68 2.68 0 0 0 6 19.3a2.58 2.58 0 0 0 1.9.78 2.68 2.68 0 0 0 2.72-2.68Zm16 0a2.68 2.68 0 1 0-.78 1.9 2.58 2.58 0 0 0 .86-1.91Zm-14.83 0a3.86 3.86 0 0 1-3.86 3.88 3.88 3.88 0 0 1-3.8-3.88 3.71 3.71 0 0 1 1.14-2.73 3.74 3.74 0 0 1 2.74-1.13 3.86 3.86 0 0 1 3.85 3.86Zm16 0a3.87 3.87 0 0 1-6.6 2.73 3.86 3.86 0 0 1 0-5.46 3.87 3.87 0 0 1 6.7 2.72Zm-13.39 0a6.18 6.18 0 0 0-1.88-4.53A6.18 6.18 0 0 0 8 11a6.24 6.24 0 0 0-3.21.86 6.39 6.39 0 0 0-2.33 2.34 6.43 6.43 0 0 0 0 6.43A6.39 6.39 0 0 0 4.82 23a6.24 6.24 0 0 0 3.18.85A6.18 6.18 0 0 0 12.56 22a6.18 6.18 0 0 0 1.88-4.57Zm9.28-8A19.08 19.08 0 0 0 16 7.93a19.84 19.84 0 0 0-8 1.53 7.78 7.78 0 0 1 3.1.63 7.94 7.94 0 0 1 2.53 1.7 8.11 8.11 0 0 1 1.69 2.54 7.76 7.76 0 0 1 .63 3.1 8 8 0 0 1 2.24-5.56 8.14 8.14 0 0 1 2.47-1.71 7.68 7.68 0 0 1 3-.69Zm6.65 8A6.41 6.41 0 0 0 24 11a6.43 6.43 0 0 0-6.42 6.42A6.43 6.43 0 0 0 24 23.85a6.41 6.41 0 0 0 6.38-6.42Zm-3.59-7.83H32a7.36 7.36 0 0 0-1 1.59 6.14 6.14 0 0 0-.56 1.59 8 8 0 0 1 .46 8.68 8 8 0 0 1-13.07 1A30.62 30.62 0 0 0 16 24.94a11.39 11.39 0 0 0-.74-1.15q-.59-.84-1-1.35A7.94 7.94 0 0 1 8 25.39a7.94 7.94 0 0 1-6.89-4 8 8 0 0 1 .46-8.68A6.14 6.14 0 0 0 1 11.16a7.36 7.36 0 0 0-1-1.59h5.07A16.72 16.72 0 0 1 10 7.4a22.58 22.58 0 0 1 6-.78 21.3 21.3 0 0 1 5.85.78 17 17 0 0 1 4.83 2.18Z">
        </path>
      </symbol>
      <symbol id="svg_icon_foursquare" viewBox="0 0 32 32">
        <path fill="currentColor"
          d="M28.7.93A2.28 2.28 0 0 0 26.86 0H5.14a2.29 2.29 0 0 0-2.28 2.29v27.42a2.29 2.29 0 0 0 3.9 1.62l8.47-8.47h5.91a2.29 2.29 0 0 0 2.18-1.6L29 3a2.28 2.28 0 0 0-.34-2Zm-6.38 8.21h-8a2.29 2.29 0 1 0 0 4.57h6.61l-1.43 4.57h-5.21a2.29 2.29 0 0 0-1.62.67L7.43 24.2V4.57h16.32Z">
        </path>
      </symbol>
      <symbol id="svg_icon_tiktokfill" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M19.555 6.685a4.793 4.793 0 0 1-3.77-4.244V2h-3.444v13.67a2.896 2.896 0 0 1-5.2 1.743 2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 1 0 5.462 6.268V8.686a8.18 8.18 0 0 0 4.772 1.526V6.789c-.336 0-.673-.034-1.002-.104Z">
        </path>
      </symbol>
      <symbol id="svg_icon_website" viewBox="0 0 20 20">
        <path fill="currentColor"
          d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0M1.11 9.68h2.51c.04.91.167 1.814.38 2.7H1.84a7.86 7.86 0 0 1-.73-2.7m8.57-5.4V1.19a4.13 4.13 0 0 1 2.22 2c.205.347.386.708.54 1.08zm3.22 1.35c.232.883.37 1.788.41 2.7H9.68v-2.7zM8.32 1.19v3.09H5.56A8.53 8.53 0 0 1 6.1 3.2a4.13 4.13 0 0 1 2.22-2.01m0 4.44v2.7H4.7c.04-.912.178-1.817.41-2.7zm-4.7 2.69H1.11a7.86 7.86 0 0 1 .73-2.7H4a14.13 14.13 0 0 0-.38 2.7M4.7 9.68h3.62v2.7H5.11a12.88 12.88 0 0 1-.41-2.7m3.63 4v3.09a4.13 4.13 0 0 1-2.22-2a8.53 8.53 0 0 1-.54-1.08zm1.35 3.09v-3.04h2.76a8.53 8.53 0 0 1-.54 1.08a4.13 4.13 0 0 1-2.22 2zm0-4.44v-2.7h3.62a12.88 12.88 0 0 1-.41 2.7zm4.71-2.7h2.51a7.86 7.86 0 0 1-.73 2.7H14c.21-.87.337-1.757.38-2.65zm0-1.35A14.13 14.13 0 0 0 14 5.63h2.16c.403.85.65 1.764.73 2.7zm1-4H13.6a8.92 8.92 0 0 0-1.39-2.52a8 8 0 0 1 3.14 2.52zm-9.6-2.52A8.92 8.92 0 0 0 4.4 4.28H2.65a8 8 0 0 1 3.14-2.52m-3.15 12H4.4a8.92 8.92 0 0 0 1.39 2.52a8 8 0 0 1-3.14-2.55zm9.56 2.52a8.92 8.92 0 0 0 1.39-2.52h1.76a8 8 0 0 1-3.14 2.48z" />
      </symbol>
      <symbol id="svg_icon_gmail" viewBox="0 0 256 193">
        <path fill="#4285f4"
          d="M58.182 192.05V93.14L27.507 65.077L0 49.504v125.091c0 9.658 7.825 17.455 17.455 17.455z" />
        <path fill="#34a853"
          d="M197.818 192.05h40.727c9.659 0 17.455-7.826 17.455-17.455V49.505l-31.156 17.837l-27.026 25.798z" />
        <path fill="#ea4335"
          d="m58.182 93.14l-4.174-38.647l4.174-36.989L128 69.868l69.818-52.364l4.669 34.992l-4.669 40.644L128 145.504z" />
        <path fill="#fbbc04" d="M197.818 17.504V93.14L256 49.504V26.231c0-21.585-24.64-33.89-41.89-20.945z" />
        <path fill="#c5221f" d="m0 49.504l26.759 20.07L58.182 93.14V17.504L41.89 5.286C24.61-7.66 0 4.646 0 26.23z" />
      </symbol>

      <symbol id="svg_icon_shopify" viewBox="0 0 80 80">
        <g fill="none" fill-rule="evenodd">
          <path d="m0 0h80v80h-80z" fill="#currentColor" />
          <g fill-rule="nonzero" transform="translate(10 6)">
            <path
              d="m51.9888814 13.1534906c-.0484068-.338509-.3388475-.5319427-.5808814-.5319427s-5.4215593-.3868673-5.4215593-.3868673-3.5821017-3.5785232-4.0177627-3.96539057c-.3872543-.38686737-1.1617627-.29015053-1.4522034-.19343368-.0484068 0-.7745085.2417921-2.0330848.62865947-1.2101695-3.53016475-3.3400678-6.72182055-7.0673898-6.72182055-.0968136 0-.1936271 0-.3388475 0-1.0165423-1.35403579-2.3719322-1.98269527-3.4852881-1.98269527-8.7132203 0-12.8762034 10.8806448-14.1831864 16.3935048-3.3884746 1.0638853-5.80881359 1.7892616-6.09925427 1.8859784-1.88786441.5803011-1.93627119.6286595-2.17830509 2.4179211-.19362711 1.3540358-5.13111864 39.5571886-5.13111864 39.5571886l38.4833898 7.2054047 20.8633221-4.4973331c0-.0483585-7.3094238-49.470665-7.3578305-49.8091739zm-15.6353899-3.86867372c-.9681356.29015053-2.0814915.62865947-3.2432542 1.01552682 0-.2417921 0-.43522577 0-.72537629 0-2.12777054-.2904407-3.8686737-.7745085-5.27106792 1.9362712.29015053 3.1948475 2.46627948 4.0177627 4.98091739zm-6.4381017-4.49733318c.5324746 1.3540358.8713221 3.24001423.8713221 5.851369v.3868673c-2.1298983.6770179-4.405017 1.3540358-6.7285424 2.0794122 1.306983-4.98091743 3.7757288-7.39883849 5.8572203-8.3176485zm-2.5655593-2.46627948c.3872542 0 .7745085.14507526 1.1133559.38686737-2.8075932 1.30567737-5.7604067 4.59405002-7.018983 11.21915371-1.8394576.5803011-3.6305085 1.1122437-5.3247458 1.6441863 1.4522034-5.0292758 4.9858983-13.25020738 11.2303729-13.25020738z"
              fill="#95bf47" />
            <path
              d="m51.408 12.5731895c-.2420339 0-5.4215593-.3868673-5.4215593-.3868673s-3.5821017-3.57852322-4.0177627-3.96539059c-.1452204-.14507526-.3388475-.24179211-.5324746-.24179211l-2.9044068 59.4808581 20.863322-4.4973331s-7.3094237-49.470665-7.3578305-49.8091739c-.0968135-.338509-.3872542-.5319427-.6292881-.5803011z"
              fill="#5e8e3e" />
            <path
              d="m31.416 24.0824938-2.5655593 7.6406305s-2.2751187-1.2089605-5.0343051-1.2089605c-4.0661695 0-4.2597966 2.5629963-4.2597966 3.1916558 0 3.4818063 9.1004746 4.8358421 9.1004746 13.0084153 0 6.43167-4.0661695 10.5904943-9.5845424 10.5904943-6.6317288 0-9.97179662-4.1104658-9.97179662-4.1104658l1.79105082-5.851369s3.4852882 2.9982221 6.3896949 2.9982221c1.9362712 0 2.7107797-1.499111 2.7107797-2.6113547 0-4.5456916-7.4546441-4.7391253-7.4546441-12.2346806 0-6.2865948 4.5018305-12.3797558 13.6507119-12.3797558 3.4852881-.0483585 5.2279322.9671684 5.2279322.9671684z"
              fill="#fff" />
          </g>
        </g>
      </symbol>
    </svg>
  </div>
</template>


<script>

export default {
  components: {
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss"></style>
