<template>
  <el-form ref="formRef" :model="formData" :rules="formRules" label-position="left" label-width="auto"
    style="max-width: 600px" require-asterisk-position="right" @keydown="viewData.error = null">

    <alert :info="viewData" />

    <el-form-item label="Current password" prop="password">
      <el-input v-model="formData.password" placeholder="" show-password />
    </el-form-item>
    <el-form-item label="New password" prop="newPassword">
      <el-input v-model="formData.newPassword" placeholder="" show-password />
    </el-form-item>
    <!-- <el-form-item label="Retype new password" prop="newPassword2">
      <el-input v-model="formData.newPassword2" type="password" @input="formatPhoneNumber" placeholder="" :validate-event="viewData.validateEvent"/>
    </el-form-item> -->
    <el-form-item style="margin-top:30px">
      <el-button type="primary" :loading="viewData.loading" @click.prevent="handleChangePassword">Update my
        password</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import * as validatorUtils from '@/base/utils/validatorUtils'

export default {
  components: {
  },
  props: {
  },
  directives: {

  },
  data() {
    return {
      session: this.$session,
      viewData: {
        loading: false,
        error: null,
        success: null,
        validateEvent: false
      },
      formData: {
        password: '',
        newPassword: '',
      },
      formRules: {
        password: [
          { required: true, message: this.$t('login.inputPassword.error'), trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: this.$t('login.inputPassword.error'), trigger: "blur" },
          { validator: validatorUtils.validatePwd, message: this.$t('login.inputPassword.validateError'), triger: 'blur' },
          { min: 8, max: 14, message: this.$t('login.inputPassword.lengthError'), trigger: 'change' },
        ],
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {

    handleChangePassword() {
      this.viewData.validateEvent = true

      this.$refs.formRef?.validate((valid) => {
        if (!valid)
          return

        this.viewData.loading = true

        const data = {
          id: this.session.user.id,
          ...this.formData,
        }

        this.$api.userApi.changePassword(data).then(response => {
          this.viewData.success = 'The password has been successfully changed.'
          this.viewData.error = null
        }).catch((error) => {
          this.viewData.error = error.message ?? error?.status?.text
        }).finally(() => {
          this.viewData.loading = false
        })
      })
    },

  }
}
</script>

<style scoped lang="scss">
.org-info {
  .title {
    margin-left: 6px;
  }

  .save-button {
    width: 100px;

    span {
      color: var(--el-color-white) !important;
    }
  }
}

.avatr {
  margin-bottom: 18px;
}

.social-meida {
  margin: 4px 0;
}

.el-upload-dragger {
  width: 100%;
}
</style>
