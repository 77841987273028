import { request } from '@/base/api/request'


const api = {
  create: '/org/create',
  update: '/org/update',
  delete_: '/org/delete',

  listApp: '/org/app/list',
  addApp: '/org/app/add',
  removeApp: '/org/app/remove',
}

export function create(parameter, viewData) {
  const headers = { 'content-type': 'multipart/form-data' }
  return request({
    url: api.create,
    data:parameter,
    headers,
    viewData,
  })
}


export function update(parameter, viewData) {
  const headers = { 'content-type': 'multipart/form-data' }
  return request({
    url: api.update,
    data:parameter,
    headers,
    viewData,
  })
}

export function delete_(parameter, viewData) {
  return request({
    url: api.delete_,
    data: parameter,
    viewData,
  })
}


////////////////////////////////////
// org App
////////////////////////////////////
export function listApp(parameter, viewData) {
  return request({
    url: api.listApp,
    data: parameter,
    viewData,
  })
}

export function addApp(parameter, viewData) {
  return request({
    url: api.addApp,
    data: parameter,
    viewData,
  })
}

export function removeApp(parameter, viewData) {
  return request({
    url: api.removeApp,
    data: parameter,
    viewData
  })
}
