
/**
 * get value from data by path
 * @param {*} data 
 * @param {*} key 
 * @returns 
 */
export function getValue(data, key) {
  return key.split('.').reduce((o, i) => {
    if (o) return o[i]
  }, options)
}

/**
 * generate and download text file according to the content
 */
export function generateTxtFile(fileName, textContent) {
  const blob = new Blob([textContent], { type: "text/plain" });
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str) => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str) => {
  if (!str) return ''
  return str.replace(/\-(\w)/g, (_, letter) => {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str) => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}


/**
 * @description 生成唯一 uuid
 * @returns {String}
 */
export function generateUUID() {
  let uuid = "";
  for (let i = 0; i < 32; i++) {
    let random = (Math.random() * 16) | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) uuid += "-";
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
}

/**
 * get color for name
 */
export function getColorByName(name) {
  var temp = []
  temp.push('#')
  for (let index = 0; index < name.length; index++) {
    temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16))
  }
  return temp.slice(0, 5).join('').slice(0, 4)
}

/**
 * get name initial
 * @param {*} username 
 * @returns 
 */
export function getInitial(username) {
  const parts = username.split(/[ -]/)
  let initials = ''

  for (var i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0)
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.substring(0, 3).toUpperCase()

  return initials
}

/**
 * format phone number
 */
export function formatPhoneNumber(phoneNumber) {
  // Remove any non-numeric characters from the input
  let formattedNumber = phoneNumber.replace(/\D/g, '');

  // Apply your desired phone number format
  if (formattedNumber.length >= 10) { // For example, format as (XXX) XXX-XXXX
    formattedNumber = '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, 6) + '-' + formattedNumber.substring(6, formattedNumber.length);
  }
  else if (formattedNumber.length > 6) {
    formattedNumber = '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, 6) + '-' + formattedNumber.substring(6, formattedNumber.length);
  } else if (formattedNumber.length > 3) {
    formattedNumber = '(' + formattedNumber.substring(0, 3) + ') ' + formattedNumber.substring(3, formattedNumber.length);
  }

  // Update the input value with the formatted phone number
  return formattedNumber;
}