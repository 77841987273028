
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "virtual:svg-icons-register";
import '@/base/styles/global.scss'

import { setupRouter } from "@/base/router";
import { setupI18n, t } from '@/base/locales'// 初始化多语言
import { setupSession } from "@/base/model/session"
import { setupBiz } from "@/base/business"
import { setupApi } from "@/base/api"
import { setupUtils } from "@/base/utils"
import { setupComponent } from '@/base/components' // 全局组件
import { setupDirectives } from "@/base/directives";
import { setupConfig } from "@/base/config/Config"

export const setupBase = (app) => {
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  setupConfig(app)
  setupDirectives(app)
  setupComponent(app)
  setupI18n(app)
  setupRouter(app)
  setupSession(app)
  setupBiz(app)
  setupApi(app)
  setupUtils(app)
}

export default { t }