import storage from 'store'
import session from '@/base/model/session'
import { dynamicRouters } from '@/platform/model/routerMap'
import router from "@/base/router"
import * as userApi from '@/platform/api/userApi'

/**
 * get white label
 */
session.getWhiteLabel = function () {
  const data = {
    domain: document.location.hostname
  }
  userApi.getWhiteLabel(data).then(response => {
    this.whiteLabel = response.body
    window.localStorage.setItem('whitelabel', JSON.stringify(this.whiteLabel))
  }).catch((error) => {
    try {
      const whitelabelStr = window.localStorage.getItem('whitelabel')
      if (whitelabelStr) {
        this.whiteLabel = JSON.parse(whitelabelStr)
      }
    } catch (e) {

    }
  })
}
session.getWhiteLabel()

/**
 * get user information
 */
session.getUserInfo = function () {
  userApi.getUserInfo({}).then(response => {
    const user = response.body.user
    if (!user?.id) {
      session.user = null
      session.removeAssceesToken()
      router.push({ path: '/user/login' })
    } else {
      session.user = user
      this.resetApps()
    }
  }).catch((error) => {
    session.removeAccessToken()
    router.push({ path: '/user/login' })
  }).finally(() => {
  })
}

/**
 * reset router menu
 */
session.resetApps = function () {
  const apps = dynamicRouters[0].children
  apps.forEach(app => {
    if (app.name != 'organization') {
      app.meta.hidden = true
      for (const orgApp of session.user.org_apps) {
        if (orgApp.app_id == app.name) {
          app.meta.hidden = false
        }
      }
    }
  });
  this.forceUpdateMenu++
}

// if plan change, refresh subscriptions
session.planChange = 0

// show accountDrawer
session.accountDrawer = {}
session.showAccountDrawer = function () {
  this.accountDrawer.visible = true
}