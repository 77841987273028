export default {
  inputText: '请输入',
  selectText: '请选择',
  startTimeText: '开始时间',
  endTimeText: '结束时间',
  login: '登录',
  required: '该项为必填项',
  loginOut: '退出系统',
  document: '项目文档',
  reminder: '温馨提示',
  loginOutMessage: '是否退出本系统？',
  back: '返回',
  ok: '确定',
  cancel: '取消',
  reload: '重新加载',
  closeTab: '关闭标签页',
  closeTheLeftTab: '关闭左侧标签页',
  closeTheRightTab: '关闭右侧标签页',
  closeOther: '关闭其它标签页',
  closeAll: '关闭全部标签页',
  prevLabel: '上一步',
  nextLabel: '下一步',
  skipLabel: '跳过',
  doneLabel: '结束',
  menu: '菜单',
  menuDes: '以路由的结构渲染的菜单栏',
  collapse: '展开缩收',
  collapseDes: '展开和缩放菜单栏',
  tagsView: '标签页',
  tagsViewDes: '用于记录路由历史记录',
  tool: '工具',
  toolDes: '用于设置定制系统',
  query: '查询',
  reset: '重置',
  shrink: '收起',
  expand: '展开',
  delMessage: '是否删除所选中数据？',
  delWarning: '提示',
  delOk: '确定',
  delCancel: '取消',
  delNoData: '请选择需要删除的数据',
  delSuccess: '删除成功',
  refresh: '刷新',
  fullscreen: '全屏',
  size: {
    name: '尺寸',
    default: '默认',
    large: '大',
    small: '小'
  },
  columnSetting: '列设置',
  lengthRange: '长度在 {min} 到 {max} 个字符',
  notSpace: '不能包含空格',
  notSpecialCharacters: '不能包含特殊字符',
  isEqual: '两次输入不一致',
  setting: '设置'
}