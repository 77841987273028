

export const setCssVar = function (prop, val, dom = document.documentElement) {
  dom.style.setProperty(prop, val)
}

export const getCssVar = function (prop, dom = document.documentElement) {
  return getComputedStyle(dom).getPropertyValue(prop)
}

export const setAttribute = function (prop, val, dom = document.documentElement) {
  dom?.setAttribute(prop, val)
}

export const getAttribute = function (prop, dom = document.documentElement) {
  return dom?.getAttribute(prop)
}


export const changeTheme = function (primaryColor) {
  const colorObj = {
    primaryColor: primaryColor,
    primaryDarkColor: lightDarkenColor(primaryColor, -20),
    primaryLightColor: colorRgba(primaryColor, 0.3),
    primaryLightestColor: colorRgba(primaryColor, 0.12),
    primaryTransparencyColor: colorRgba(primaryColor, 0.1)
  }
  Object.entries(colorObj).forEach(ele => {
    // 逐个设置 css 变量到 body 上
    document.body.style.setProperty(`--${ele[0]}`, ele[1])
  })
}


export const changeNamespace = function (namespace) {
  document.body.style.setProperty('--namespace', namespace)
}