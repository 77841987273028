<template>
  <SvgIcons />
  <ElConfigProvider :namespace="theme.elNamespace" :locale="theme.locale" :button="buttonConfig"
    :size="theme.assemblySize">
    <slot></slot>
  </ElConfigProvider>
</template>


<script>
import { useWindowSize } from '@vueuse/core'
import { ElConfigProvider } from 'element-plus'
import SvgIcons from '@/base/components/Icon/SvgIcons.vue'

export default {
  components: {
    SvgIcons,
  },
  props: {
  },
  data() {
    return {
      width: useWindowSize().width,
      session: this.$session,
      options: this.$session.options,
      theme: this.$session.theme,
      buttonConfig: { autoInsertSpace: false },
    }
  },
  computed: {
  },
  watch: {
    width: function (newVal) {
      if (newVal < 768) {
        this.options.mobile = true
        this.options.collapse = true
        this.theme.layout = 'classic2'
        document.documentElement.style.setProperty('--left-menu-min-width', '0')
      } else {
        this.options.mobile = false
        document.documentElement.style.setProperty('--left-menu-min-width', '64px')
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="less"></style>
