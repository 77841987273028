
/**
 * extend avatar 
 */
export const avatar = {
  menuItems: []
}

/**
 * extend components
 */
export const components = []