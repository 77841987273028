export default {
  dashboard: {
    setting: 'Organization',
    dataAnalysis: 'Data analysis',
    billingInfo: 'Subscriptions', //'Billing information',
  },
  inviteUser: {
    title: 'Invite a member',
    email: {
      label: 'Invite a member to your organization',
      placeholder: 'Please enter the invited user\'s email.',
      error: 'Please enter a valid email address',
    },
  },
  integration: {
    email: {
      name: {
        validate: 'Name is required.'
      },
      provider: {
        validate: 'Provider is required.'
      },
      provider_name: {
        validate: 'Provider name is required.'
      },
      host: {
        validate: 'Host is required.'
      },
      port: {
        validate: 'Port is required.'
      },
      email: {
        validate: 'A valid email is required.'
      },
      password: {
        validate: 'Password is required.'
      },
    }
  },
  app:{
    drawerTitle: 'Organization applications'
  }
}
