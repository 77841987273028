
import biz  from "@/base/business"
import userBiz  from "./userBiz"

const bizList = {
  userBiz,
}

const setup = function () {
  for (const [key, component] of Object.entries(bizList)) {
    biz[key] = component
  }
}

setup()