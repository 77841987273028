

const biz = {
}

const bizPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$biz = biz
  }
}

export const setupBiz = (app) => {
  app.use(bizPlugin)
}

export default biz