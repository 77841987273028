import { createApp } from 'vue'
import { setupApp } from "@/platform"
import { setupBase } from "@/base";
import App from './App.vue'
// import './qiankun'

const app = createApp(App)
setupApp(app)
setupBase(app)
app.mount('#app')
