import keepAlive from './keepAlive'
import * as routerMap from './routerMap'

const tabs = {
  menuItems: []
}

tabs.initTabs = function () {
  routerMap.getFlatMenuList().forEach(item => {
    if (item.meta?.affix && !item.meta.hidden && !item.meta.full && !item.children) {
      const tabsParams = {
        icon: item.meta.icon,
        title: item.meta.title,
        path: item.path,
        name: item.name,
        close: !item.meta.affix,
        keepAlive: item.meta.keepAlive
      };
      this.addTabs(tabsParams);
    }
  });
},

  tabs.addTabs = function (tabItem) {
    if (this.menuItems.every(item => item.path !== tabItem.path)) {
      this.menuItems.push(tabItem);
    }
    // add keepalive
    if (!this.menuItems.includes(tabItem.name) && tabItem.isKeepAlive) {
      keepAlive.addName(tabItem.path);
    }
  }

// Remove Tabs
tabs.removeTabs = function (tabPath, isCurrent = true) {
  if (isCurrent) {
    this.menuItems.forEach((item, index) => {
      if (item.path !== tabPath) return;
      const nextTab = this.menuItems[index + 1] || this.menuItems[index - 1];
      if (!nextTab) return;
      router.push(nextTab.path);
    });
  }
  // remove keepalive
  const tabItem = this.menuItems.find(item => item.path === tabPath);
  tabItem?.isKeepAlive && keepAliveStore.removeKeepAliveName(tabItem.path);
  // set tabs
  this.menuItems = this.menuItems.filter(item => item.path !== tabPath);
}

// Close Tabs On Side
tabs.closeTabsOnSide = function (path, type) {
  const currentIndex = this.menuItems.findIndex(item => item.path === path);
  if (currentIndex !== -1) {
    const range = type === "left" ? [0, currentIndex] : [currentIndex + 1, this.menuItems.length];
    this.menuItems = this.menuItems.filter((item, index) => {
      return index < range[0] || index >= range[1] || !item.close;
    });
  }
  // set keepalive
  const KeepAliveList = this.menuItems.filter(item => item.isKeepAlive);
  keepAliveStore.setKeepAliveName(KeepAliveList.map(item => item.path));
}

// Close MultipleTab
tabs.closeMultipleTab = function (tabsMenuValue) {
  this.menuItems = this.menuItems.filter(item => {
    return item.path === tabsMenuValue || !item.close;
  });
  // set keepalive
  const KeepAliveList = this.menuItems.filter(item => item.isKeepAlive);
  keepAliveStore.setKeepAliveName(KeepAliveList.map(item => item.path));
}

// Set Tabs
tabs.setTabs = function (menuItems) {
  this.menuItems = menuItems;
}

// Set Tabs Title
tabs.setTabsTitle = function (title) {
  this.menuItems.forEach(item => {
    if (item.path == getUrlWithParams()) item.title = title;
  });
}

export default tabs