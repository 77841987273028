<template>
  <!-- 表格主体 -->
  <div class="card table-main">
    <el-alert v-if="table.error" :title="table.error" type="error" style="margin-bottom: 18px; "
      @close="table.error = null" />
    <!-- 表格头部 操作按钮 -->
    <div class="table-header">
      <div class="header-button-lf">
        <slot name="tableHeader" />
      </div>
      <div class="header-button-ri">
        <slot name="toolButton">
          <div class="flx-center">
            <el-input v-if="showToolButton('search')" v-model="table.search" placeholder="" prefix-icon="Search"
              @input="handleSearch" />
            <el-button v-if="showToolButton('refresh')" icon="Refresh" circle @click="handleRefresh" title="Refresh" />
          </div>
        </slot>
      </div>
    </div>
    <!-- 表格主体 -->
    <el-table ref="tableRef" :data="table.data" v-bind="$attrs" @selection-change="handleSelectionChange">
      <!-- 列展开的插槽 -->
      <el-table-column type="expand" v-if="table.expand">
        <template #default="scope">
          <slot name="expand" v-bind="scope" />
        </template>
      </el-table-column>

      <!-- 默认插槽 -->
      <template v-for="column in table.columns" :key="column">
        <el-table-column v-if="column.visible != false" v-bind="column">
          <template v-if="column.slotHeader" #default="scope">
            <slot :name="column.slotHeader" v-bind="scope" />
          </template>
          <template v-if="column.slot" #default="scope">
            <slot :name="column.slot" v-bind="scope" />
          </template>
          <template v-else-if="column.tag" #default="scope">
            <el-tag :type="column.tagTypes[scope.row[column.prop]]">{{ scope.row[column.prop] }}</el-tag>
          </template>

          <template v-if="column.children?.length > 0">
            <TableColumn v-for="(subColumn, index) in column.children" :key="index" :column="subColumn" />
          </template>
        </el-table-column>

      </template>


      <!-- 插入表格最后一行之后的插槽 -->
      <template #append>
        <slot name="append" />
      </template>

      <!-- 无数据 -->
      <template #empty>
        <div class="table-empty">
          <slot name="empty">
            <img src="@/assets/images/notData.png" alt="notData" />
            <div style="margin-top: -20px; margin-bottom: 20px;">No Data</div>
          </slot>
        </div>
      </template>
    </el-table>
    <!-- 分页组件 -->
    <slot name="pagination">
      <el-pagination v-if="table.pagination" :background="true" :current-page="table.pagination.current"
        :page-size="table.pagination.pageSize" :total="table.pagination.total" :page-sizes="pageSizes"
        :layout="pageLayout" @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"></el-pagination>
    </slot>
  </div>
</template>

<script>
import TableColumn from './TableColumn.vue'

export default {
  components: {
    TableColumn,
  },
  props: {
    table: {
      type: Object,
      default: {}
    },
  },
  directives: {

  },
  data() {
    return {
    }
  },
  computed: {
    tableRef() {
      return this.$refs.tableRef
    },
    pageSizes() {
      return this.table.pagination?.sizes || [10, 20, 50, 100]
    },
    pageLayout() {
      return this.table.pagination?.layout || "total, prev, pager, next,sizes, "
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
    if (!this.table.query) {
      return
    }

    this.table.query()

    this.table.intervalId = setInterval(() => {
      if (this.table.refresh) {
        this.table.query()
      }
    }, this.table.interval || 5000)
  },
  unmounted() {
    clearInterval(this.table.intervalId)
  },
  deactivated() {
    clearInterval(this.table.intervalId)
  },

  methods: {
    handleCurrentPageChange(current) {
      if (this.table.query) {
        this.table.pagination.current = current
        this.table.query()
      }

      this.$emit('current-page-change', current)
    },
    handlePageSizeChange(pageSize) {
      if (this.table.query) {
        this.table.pagination.pageSize = pageSize
        this.table.query()
      }
      this.$emit('page-size-change', pageSize)
    },
    handleSearch() {
      clearTimeout(this.table.searchTimer)
      this.table.searchTimer = setTimeout(() => {
        if (this.table.query) {
          this.table.query()
        }
        this.$emit('search')
      }, 500)
    },
    handleRefresh() {
      if (this.table.query) {
        this.table.query()
      }
      this.$emit('refresh')
    },
    handleSelectionChange(selection) {
      this.table.selectedRows = selection
    },
    showToolButton(key) {
      return Array.isArray(this.table.toolButton) ? this.table.toolButton.includes(key) : this.toolButton;
    },

  }
}
</script>

<style scoped lang="scss">
/* proTable */
.table-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .table-header {
    margin-bottom: 10px;
    position: relative;

    .header-button-lf {
      // float: left;
    }

    .header-button-ri {
      // float: right;
      position: absolute;
      right: 0px;
      top: 0;
    }

    .el-button {
      margin: 0 10px;
    }
  }

  // el-table 表格样式
  // 修复 safari 浏览器表格错位 https://github.com/HalseySpicy/Geeker-Admin/issues/83
  .el-table table {
    width: 100%;
  }

  .table-empty {
    margin-top: 30px;
  }

  // 表格 pagination 样式
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}


.el-table thead {
  height: 45px;
  font-size: 15px;
  font-weight: bold;
  color: var(--el-text-color-primary);
  background: var(--el-fill-color-light);
}
</style>
