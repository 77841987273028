import { createI18n } from 'vue-i18n'
import options from '@/base/model/options'
import * as dictionary from '@/base/model/dictionary'

// 对自动引入的 modules 进行分类 en、zh-cn、zh-tw
export const loadLang = (modules)=> {
  const messages = {}
  for (const path in modules) {
    const key = path.match(/(\S+)\/(\S+).ts/);

    key[1] = key[1].replace('./lang/', '')
    if (!messages[key[1]])
      messages[key[1]] = {}

    messages[key[1]][key[2]] = modules[path].default
  }

  return messages
}


export const getKey = (namespace, key) => {
  if (!namespace) {
    return key
  }
  if (key.startsWith(namespace)) {
    return key
  }
  return `${namespace}.${key}`
}

export const setLocale = async (lang = options.defaultLocale.lang) => {
  if (i18n.locale !== lang) {
    // if (!loadedLanguages.includes(lang)) {
    //   let langfile = `./lang/${lang}.ts`
    //   const locale = await import(langfile)
    //   const messages = locale.default ?? {}
    //   i18n.global.setLocaleMessage(lang, messages)
    //   loadedLanguages.push(lang)
    // }

    if (i18n.mode === 'legacy') {
      i18n.global.locale = lang
    } else {
      i18n.global.locale.value = lang
    }
    options.locale = dictionary.localeMap[lang]
    document.querySelector('html')?.setAttribute('lang', lang)
  }
  // moment.updateLocale(locale.momentName, locale.momentLocale)
}

export const t = (key) => {
  return i18n.global.t(`${key}`)
}

export const addMessages = function (messages) {
  for (const [lang, subMessages] of Object.entries(messages)) {
    i18n.global.mergeLocaleMessage(lang, subMessages)
  }
}

const modules = import.meta.glob('./lang/*/*.ts', { eager: true });
const messages = loadLang(modules)

const i18n = createI18n({
  legacy: false,
  globalInjection: true, // 全局注册$t方法
  locale: options.defaultLocale.name,
  fallbackLocale: options.defaultLocale.name,
  messages: messages,
  availableLocales: dictionary.languageList.map((item) => item.value),
  sync: true,
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true
})

export const setupI18n = async (app) => {
  setLocale()
  app.use(i18n)
}

export default i18n
