import { request } from '@/base/api/request'

const api = {
  list: '/org/integration/list',
  get: '/org/integration/get',
  add: '/org/integration/add',
  update: '/org/integration/update',
  delete: '/org/integration/delete',

  listEmailServers: '/org/integration/emailserver/list',
  getEmailServer: '/org/integration/emailserver/get',
  addEmailServer: '/org/integration/emailserver/add',
  updateEmailServer: '/org/integration/emailserver/update',
  deleteEmailServer: '/org/integration/emailserver/delete',
  testEmailServer: '/org/integration/emailserver/test',

  listEmails: '/org/integration/email/list',

  listPhones: '/org/integration/phone/list',
  getPhone: '/org/integration/phone/get',
  addPhone: '/org/integration/phone/add',
  updatePhone: '/org/integration/phone/update',
  updatePhoneName: '/org/integration/phone/update-name',
  updatePhonePlan: '/org/integration/phone/update-plan',
  deletePhone: '/org/integration/phone/delete',
  listAvaliablePhoneNumbers: '/org/integration/phone/avaliable',

  getPhoneA2p: '/org/integration/phone/a2p/get',
  registerPhoneA2p: '/org/integration/phone/a2p/register',
  // updatePhoneA2p: '/org/integration/phone/a2p/update',

}

export function list(parameter, viewData) {
  return request({
    url: api.list,
    data: parameter,
    viewData,
  })
}

export function get(parameter, viewData) {
  return request({
    url: api.get,
    data: parameter,
    viewData,
  })
}
export function add(parameter, viewData) {
  return request({
    url: api.add,
    data: parameter,
    viewData,
  })
}
export function update(parameter, viewData) {
  return request({
    url: api.update,
    data: parameter,
    viewData,
  })
}
export function delete_(parameter, viewData) {
  return request({
    url: api.delete,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// Email
//////////////////////////////////////
export function listEmails(parameter, viewData) {
  return request({
    url: api.listEmails,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// EmailServer
//////////////////////////////////////
export function listEmailServers(parameter, viewData) {
  return request({
    url: api.listEmailServers,
    data: parameter,
    viewData,
  })
}

export function getEmailServer(parameter, viewData) {
  return request({
    url: api.getEmailServer,
    data: parameter,
    viewData,
  })
}


export function addEmailServer(parameter, viewData) {
  return request({
    url: api.addEmailServer,
    data: parameter,
    viewData,
  })
}

export function updateEmailServer(parameter, viewData) {
  return request({
    url: api.updateEmailServer,
    data: parameter,
    viewData,
  })
}

export function deleteEmailServer(parameter, viewData) {
  return request({
    url: api.deleteEmailServer,
    data: parameter,
    viewData,
  })
}

export function testEmailServer(parameter, viewData) {
  return request({
    url: api.testEmailServer,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// phone
//////////////////////////////////////
export function listAvaliablePhoneNumbers(parameter, viewData) {
  return request({
    url: api.listAvaliablePhoneNumbers,
    data: parameter,
    viewData,
  })
}


export function listPhones(parameter, viewData) {
  return request({
    url: api.listPhones,
    data: parameter,
    viewData,
  })
}

export function addPhone(parameter, viewData) {
  return request({
    url: api.addPhone,
    data: parameter,
    viewData,
  })
}

export function updatePhone(parameter, viewData) {
  return request({
    url: api.updatePhone,
    data: parameter,
    viewData,
  })
}


export function updatePhoneName(parameter, viewData) {
  return request({
    url: api.updatePhoneName,
    data: parameter,
    viewData,
  })
}

export function updatePhonePlan(parameter, viewData) {
  return request({
    url: api.updatePhonePlan,
    data: parameter,
    viewData,
  })
}

export function deletePhone(parameter, viewData) {
  return request({
    url: api.deletePhone,
    data: parameter,
    viewData,
  })
}

export function getPhone(parameter, viewData) {
  return request({
    url: api.getPhone,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// phone a2p
//////////////////////////////////////

export function registerPhoneA2p(parameter, viewData) {
  return request({
    url: api.registerPhoneA2p,
    data: parameter,
    viewData,
  })
}

// export function updatePhoneA2p(parameter, viewData) {
//   return request({
//     url: api.updatePhoneA2p,
//     data: parameter,
//     viewData,
//   })
// }


export function getPhoneA2p(parameter, viewData) {
  return request({
    url: api.getPhoneA2p,
    data: parameter,
    viewData,
  })
}
