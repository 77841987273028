export default {
  projectSetting: '项目配置',
  theme: '主题',
  layout: '布局',
  systemTheme: '系统主题',
  menuTheme: '菜单主题',
  interfaceDisplay: '界面显示',
  breadcrumb: '面包屑',
  breadcrumbIcon: '面包屑图标',
  collapseMenu: '折叠菜单',
  hamburgerIcon: '折叠图标',
  screenfullIcon: '全屏图标',
  sizeIcon: '尺寸图标',
  localeIcon: '多语言图标',
  tagsView: '标签页',
  logo: 'Logo',
  greyMode: '灰色模式',
  fixedHeader: '固定头部',
  headerTheme: '头部主题',
  cutMenu: '切割菜单',
  copy: '拷贝',
  clearAndReset: '清除缓存并且重置',
  copySuccess: '拷贝成功',
  copyFailed: '拷贝失败',
  footer: '页脚',
  uniqueOpened: '菜单手风琴',
  tagsViewIcon: '标签页图标',
  dynamicRouter: '开启动态路由',
  serverDynamicRouter: '服务端动态路由',
  reExperienced: '请重新退出登录体验',
  fixedMenu: '固定菜单'
}