import NProgress from "nprogress";
import "nprogress/nprogress.css";
import options from "@/base/model/options"
import { useCssVar } from '@vueuse/core'
import { nextTick } from 'vue'

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

const initColor = async () => {
  await nextTick()
  const bar = document.getElementById('nprogress')?.getElementsByClassName('bar')[0]
  if (bar) {
    const primaryColor = useCssVar('--el-color-primary', document.documentElement)
    bar.style.background = primaryColor
  }
}

initColor()

export default NProgress;