<template>
  <SvgIcon :class="[name, 'svg-icon-' + shape]" :link="link" :size="size" :color="color" :style="style" />
</template>

<script>

const links = {
  website: 'svg_icon_website',
  facebook: 'svg_icon_facebook',
  facebook_colored: 'svg_icon_facebook_colored',
  linkedin: 'svg_icon_linkedin',
  instagram: 'svg_icon_instagram',
  xtwitter: 'svg_icon_xtwitter',
  youtube: 'svg_icon_youtube',
  'google-my-business': 'svg_icon_google-my-business',
  rss: 'svg_icon_rss',
  yelp: 'svg_icon_yelp',
  whatsapp: 'svg_icon_whatsapp',
  pinterest: 'svg_icon_pinterest',
  waze: 'svg_icon_waze',
  vimeo: 'svg_icon_vimeo',
  snapchat: 'svg_icon_snapchat',
  reddit: 'svg_icon_reddit',
  tripadvisor: 'svg_icon_tripadvisor',
  foursquare: 'svg_icon_foursquare',
  tiktokfill: 'svg_icon_tiktokfill',
  gmail: 'svg_icon_gmail',
  shopify: 'svg_icon_shopify',

}

export default {
  components: {
  },
  props: {
    name: {
      type: String,
      default: 'square'
    },
    shape: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 12,
    },
    color: {
      type: String,
    },
    style: {
      type: String,
    }

  },
  directives: {

  },
  data() {
    return {
      links,
    }
  },
  computed: {
    link() {
      return this.links[this.name] || 'svg_icon_brand_' + this.name
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.website {
  &.svg-icon-circle {
    background-color: #3a5998;
  }

  &.svg-icon-square {
    color: #3a5998;
  }
}

.facebook {
  &.svg-icon-circle {
    background-color: #3a5998;
  }

  &.svg-icon-square {
    color: #3a5998;
  }
}

.linkedin {
  &.svg-icon-circle {
    background-color: #2867b2;
  }

  &.svg-icon-square {
    color: #2867b2;
  }
}

.instagram {
  &.svg-icon-circle {
    // background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%) !important;
    background-color: #833ab4;
  }

  &.svg-icon-square {
    color: #833ab4;
  }
}

.xtwitter {
  &.svg-icon-circle {
    background-color: #0F1419;
  }

  &.svg-icon-square {
    color: #0F1419;
  }
}

.youtube {
  &.svg-icon-circle {
    background-color: red;
  }

  &.svg-icon-square {
    color: red;
  }
}

.google-my-business {
  &.svg-icon-circle {
    background-color: #477cdf;
  }

  &.svg-icon-square {
    color: #477cdf;
  }
}

.rss {
  &.svg-icon-circle {
    background-color: #f39200;
  }

  &.svg-icon-square {
    color: #f39200;
  }
}

.yelp {
  &.svg-icon-circle {
    background-color: #e43340;
  }

  &.svg-icon-square {
    color: #e43340;
  }
}

.whatsapp {
  &.svg-icon-circle {
    background-color: #25d366;
  }

  &.svg-icon-square {
    color: #25d366;
  }
}

.pinterest {
  &.svg-icon-circle {
    background-color: #c8232c;
  }

  &.svg-icon-square {
    color: #c8232c;
  }
}

.waze {
  &.svg-icon-circle {
    background-color: #56cbe7;
  }

  &.svg-icon-square {
    color: #56cbe7;
  }
}

.vimeo {
  &.svg-icon-circle {
    background-color: #1ab7ea;
  }

  &.svg-icon-square {
    color: #1ab7ea;
  }
}

.snapchat {
  &.svg-icon-circle {
    background-color: #fffc00;
  }

  &.svg-icon-square {
    color: #fffc00;
  }
}

.reddit {
  &.svg-icon-circle {
    background-color: #ff4500;
  }

  &.svg-icon-square {
    color: #ff4500;
  }
}

.tripadvisor {
  &.svg-icon-circle {
    background-color: #589442;
  }

  &.svg-icon-square {
    color: #589442;
  }
}

.foursquare {
  &.svg-icon-circle {
    background-color: #ef4b78;
  }

  &.svg-icon-square {
    color: #ef4b78;
  }
}

.tiktokfill {
  &.svg-icon-circle {
    background-color: #000;
  }

  &.svg-icon-square {
    color: #000;
  }
}
</style>
