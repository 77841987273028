// import SvgIcons from "./SvgIcons.vue"
import SvgIcon from "./SvgIcon.vue"
import BrandIcon from "./BrandIcon.vue"
import { antDesignSetup } from "./AntDesignIcon"

export default { SvgIcon, BrandIcon }

export const iconSetup = function (app) {
  app.component('SvgIcon', SvgIcon)
  app.component('BrandIcon', BrandIcon)

  antDesignSetup(app)
}