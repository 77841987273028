import { errorHandler } from '@/base/api/request'
import { ElMessage } from 'element-plus'
import userBiz from '@/platform/business/userBiz'
import session from '@/base/model/session'
import api from '@/base/api'

// import * as userApi from './userApi'
// import * as orgApi from './orgApi'
// import * as orgUserApi from './orgUserApi'
// import * as orgIntegrationApi from './orgIntegrationApi'
// import * as systemApi from './systemApi'

// const apiList = {
//   userApi,
//   orgApi,
//   orgUserApi,
//   orgIntegrationApi,
//   systemApi
// }

// const setup = function () {
//   for (const [key, component] of Object.entries(apiList)) {
//     api[key] = component
//   }
// }
// setup()

const modules = import.meta.glob('./*Api.js', { eager: true });
for (let path in modules) {
  // modules[path].create().then((module) => {
  //   path = path.replace('./', '')
  //   path = path.substring(0, path.indexOf('.js'))
  //   api[path] = module
  // })
  let module = modules[path]
  path = path.replace('./', '')
  path = path.substring(0, path.indexOf('.js'))
  api[path] = module
}


errorHandler.httpErrorHandle = (error) => {
  if (error.response) {
    ElMessage.error(error.message)
  }
}

errorHandler.responseErrorHandle = (response) => {
  console.log('Error: ', response)

  if (response?.status?.code === '1000' || response?.status?.code == 'user_not_login_error') {
    userBiz.logout(true)
    session.jumpTo('login')
  } else if (response?.status?.text) {
    // ElMessage.error(response?.status?.text)
  } else if (response?.statusText) {
    //ElMessage.error(response?.statusText)
  } else if (response?.response?.statusText) {
    //ElMessage.error(response?.response?.statusText)
  } else {
    // console.log('Error: ', response)
  }
}



export default errorHandler
