import router from '@/base/router'
import options from '@/base/model/options'
import theme from '@/base/model/theme'
import * as dictionary from '@/base/model/dictionary'
import tagsView from '@/base/model/tagsView'
import user from '@/base/model/user'
import keepAlive from '@/base/model/keepAlive'
import tabs from '@/base/model/tabs'
import * as header from '@/base/model/header'
import * as routerMap from '@/base/model/routerMap'


export const ACCESS_TOKEN = 'Access-Token'

const session = {
  options,
  tabs,
  routerMap,
  dictionary,
  tagsView,
  user,
  theme,
  keepAlive,
  header,
  forceUpdateMenu: 0,
}
/**
 * 
 * @returns 
 */
session.getCurrentRoute = function () {
  return router.currentRoute?.value
}

/**
 * get access_token
 */
session.getAccessToken = function() {
  return sessionStorage.getItem(ACCESS_TOKEN)
}

session.setAccessToken = function(accessToken) {
  return sessionStorage.setItem(ACCESS_TOKEN, accessToken)
}

session.removeAccessToken = function() {
  return sessionStorage.removeItem(ACCESS_TOKEN)
}

/**
 * jump to other path
 * @param {} to 
 */
session.jumpTo = function (to) {
  if (to) {
    if (to.startsWith('/'))
      router.push({ path: to })
    else
      router.push({ name: to })
  }
}

/**
 * get userinfo
 */
session.getUserInfo = function() {

}

/**
 * logout
 */
session.logout = function () {

}

/**
 * reset session
 */
session.reset = function () {
  this.user = {}
  this.removeAccessToken()
}

/**
 * filter breadcrumb
 */
session.filterBreadcrumb = function (routes, parentPath) {
  const res = []

  for (route of routes) {
    const meta = route?.meta
    if (meta.hidden && !meta.canTo) {
      continue
    }

    const data = !meta.alwaysShow && route.children?.length === 1
      ? { ...route.children[0], path: pathResolve(route.path, route.children[0].path) }
      : { ...route }

    data.path = pathResolve(parentPath, data.path)

    if (data.children) {
      data.children = filterBreadcrumb(data.children, data.path)
    }
    if (data) {
      res.push(data)
    }
  }
  return res
}


const sessionPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$session = session
  }
}

export const setupSession = (app) => {
  session.theme.initTheme()

  app.use(sessionPlugin)
}

export default session