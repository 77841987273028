export function downloadUrl(filename, url) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(link.href)
}

export function downloadData (resp) {
  let blob = new Blob([resp.data])
  let url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', resp.fileName)
  document.body.appendChild(link)
  link.click()

  const dispose = function () {
    if (url) {
      url = window.URL.revokeObjectURL(url)
      blob = null
      link.remove()
    }
  }

  setTimeout(function () {
    dispose()
  }, 10000)
}


export function formatFileSize(fileSize) {
  if (fileSize < 1024) {
    return fileSize + 'B';
  } else if (fileSize < (1024 * 1024)) {
    var temp = fileSize / 1024;
    temp = temp.toFixed(2);
    return temp + 'KB';
  } else if (fileSize < (1024 * 1024 * 1024)) {
    var temp = fileSize / (1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'MB';
  } else {
    var temp = fileSize / (1024 * 1024 * 1024);
    temp = temp.toFixed(2);
    return temp + 'GB';
  }
}