import { createRouter, createWebHistory } from 'vue-router'
import { constants, constantRouterMap, extendRouterMap } from '@/base/model/routerMap'
import NProgress from '@/base/router/nprogress'
import options from "@/base/model/options"
import session from '@/base/model/session'

/**
 * @description 📚 路由参数配置简介
 * @param path ==> 路由菜单访问路径
 * @param name ==> 路由 name (对应页面组件 name, 可用作 KeepAlive 缓存标识 && 按钮权限筛选)
 * @param redirect ==> 路由重定向地址
 * @param component ==> 视图文件路径
 * @param meta ==> 路由菜单元信息
 * @param meta.icon ==> 菜单和面包屑对应的图标
 * @param meta.title ==> 路由标题 (用作 document.title || 菜单的名称)
 * @param meta.activeMenu ==> 当前路由为详情页时，需要高亮的菜单
 * @param meta.link ==> 路由外链时填写的访问地址
 * @param meta.hidden ==> 是否在菜单中隐藏 (通常列表详情页需要隐藏)
 * @param meta.full ==> 菜单是否全屏 (示例：数据大屏页面)
 * @param meta.affix ==> 菜单是否固定在标签页中 (首页通常是固定项)
 * @param meta.keepAlive ==> 当前路由是否缓存
 * */
const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: [...constantRouterMap, ...extendRouterMap],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = () => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !constants.NO_RESET_WHITE_LIST.includes(name)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

router.beforeEach(async (to, from, next) => {
  // 0.loop path, return directly
  if (to.fullPath == from.fullPath) {
    return
  }

  // 1.NProgress 开始
  NProgress.start();
  options.setPageLoading(true)

  // 2.判断是访问登陆页，有 Token 就在当前页面，没有 Token 重置路由到登陆页
  const token = session.getAccessToken()
  if (to.path === constants.LOGIN_PATH && constants.NO_REDIRECT_WHITE_LIST.indexOf(to.path) == -1) {
    if (token) {
      return next(from.fullPath);
    }
    return next();
  }

  // 获取user info
  if (token && !session.user?.id) {
    session.getUserInfo()
  }

  // 3.断访问页面是否在路由白名单地址(静态路由)中，如果存在直接放行
  if (constants.NO_REDIRECT_WHITE_LIST.indexOf(to.path) !== -1) {
    return next()
  }

  for (let subpath of constants.NO_REDIRECT_SUB_PATH_LIST) {
    if (to.path.indexOf(subpath) != -1) {
      return next()
    }
  }


  // 4.判断是否有 Token，没有重定向到 login 页面
  if (!token) {
    return next(`${constants.LOGIN_PATH}?redirect=${to.path}`) // 否则全部重定向到登录页
  }

  // 5.正常访问页面
  const redirectPath = from.query.redirect || to.path
  const redirect = decodeURIComponent(redirectPath)
  if (to.path != redirect)
    return next(redirect)

  return next()
})

/**
 * @description 路由跳转结束
 */
router.afterEach((to) => {
  NProgress.done();
  options.setPageLoading(false)
})

/**
 * @description 路由跳转错误
 */
router.onError(error => {
  NProgress.done();
  options.setPageLoading(false)
})


export const setupRouter = (app) => {
  extendRouterMap.forEach((route) => {
    router.addRoute(route) // 动态添加可访问路由表
  })

  app.use(router)
}

export default router
