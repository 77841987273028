import { request } from '@/base/api/request'


const api = {
  listContacts: '/crm/contact/list',
  exportContacts: '/crm/contact/export',

  
  deleteContact: '/crm/contact/delete',
}


//////////////////////////////////////////
// Contacts
//////////////////////////////////////////
export function listContacts(parameter) {
  return request({
    url: api.listContacts,
    data: parameter
  })
}

export function exportContacts(parameter) {
  return request({
    url: api.exportContacts,
    data: parameter,
    responseType: 'blob'
  })
}


export function deleteContact(parameter, viewData) {
  return request({
    url: api.deleteContact,
    data: parameter,
    viewData
  })
}