import { t } from '@/base/locales'

import * as userApi from '@/platform/api/userApi'

import router from '@/base/router'
import session from '@/base/model/session'
import { ElMessageBox, ElNotification } from 'element-plus'


const isTest = true

const userBiz = {
  activate: {
    jumpTo: '',
    email: '',
  },
  register: {
    result: '',
  },
  forgetPasword: {
    result: '',
  },
  resetPasword: {
    result: ''
  },
  acceptInvite: {
    result: ''
  },
  createPasword: {
    result: ''
  },
  dApperance: {
    viewData: {
      drawerVisible: false,
    }
  }
}

// userBiz.login = function (data) {
//   this.dLogin.loading = true
//   userApi.login(data).then(response => {
//     session.jumpTo('/')

//     ElNotification({
//       title: base.t(`login.welocme.title.${getTimeState()}`),
//       message: base.t(`login.welocme.message`),
//       type: 'success',
//       duration: 3000
//     });

//     this.dLogin.failed = false
//     this.dLogin.failedMsg = null
//   }).catch((error) => {
//     this.dLogin.failed = true
//     this.dLogin.failedMsg = error
//   }).finally(() => {
//     this.dLogin.loading = false
//   })
// }

userBiz.getValidateCode = function () {
  this.dRegister.failed = false
  this.dRegister.failedMsg = ''
  this.dRegister.getCodeLoading = true
  this.dRegister.getCodeTime = 60

  const data = {}
  userApi.getValidateCode(data).then(response => {
    // count down
    const timer = setInterval(() => {
      this.dRegister.getCodeTime.value--
      if (this.register.getCodeTime.value <= 0) {
        clearInterval(timer)
        this.dRegister.getCodeTime.value = 60
        this.dRegister.getCodeLoading.value = false
      }
    }, 1000)

  }).catch((error) => {
    this.dRegister.failed = true
    this.dRegister.failedMsg = error
  }).finally(() => {
    this.dRegister.getCodeLoading = false
  })
}

// userBiz.register = function (data) {
//   this.dRegister.viewData.failed = false
//   this.dRegister.viewData.failedMsg = ''
//   this.dRegister.viewData.loading = true

//   if (isTest) {
//     return session.jumpTo('/user/register-result')
//   }

//   userApi.register(data).then(response => {
//     session.jumpTo('/user/register-result')
//   }).catch((error) => {
//     this.dRegister.viewData.failed = true
//     this.dRegister.viewData.failedMsg = error
//   }).finally(() => {
//     this.dRegister.viewData.loading = false
//   })
// }

/**
 * logout
 */
userBiz.logout = function (directly) {
  if (directly) {
    session.reset()
    userApi.logout({})
    router.push({ path: '/user/login' })
    return
  }

  // ElMessageBox.confirm(t('common.loginOutMessage'), t('common.reminder'), {
  //   confirmButtonText: t('common.ok'),
  //   cancelButtonText: t('common.cancel'),
  //   type: 'warning'
  // }).then(() => {
    userApi.logout({}).then(response => {
      session.reset()
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      router.push({ path: '/user/login' })
    })
  // }).catch((e) => {
  //   console.error(e)
  // })
}


userBiz.showApperanceDrawer = function () {
  this.dApperance.viewData.drawerVisible = true
}

export const userPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$user = user
  }
}

export default userBiz