import * as browserUtils from './browserUtils'
import * as colorUtils from './colorUtils'
import * as dateUtils from './dateUtils'
import * as imageUtils from './imageUtils'
import * as objectUtils from './objectUtils'
import * as stringUtils from './stringUtils'
import * as styleUtils from './styleUtils'
import * as validatorUtils from './validatorUtils'
import * as fileUtils from './fileUtils'

const utils = {
  browserUtils,
  colorUtils,
  dateUtils,
  imageUtils,
  objectUtils,
  stringUtils,
  styleUtils,
  validatorUtils,
  fileUtils,
}

const utilsPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$utils = utils
  }
}

export const setupUtils = (app) => {
  app.use(utilsPlugin)
}

export default utils