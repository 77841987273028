export function formatDateTime(date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  let seconds = date.getSeconds().toString().padStart(2, '0');
  let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime
}


export function formatDate(date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let formattedDateTime = `${year}-${month}-${day}`;

  return formattedDateTime
}


export function formatTime(date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  let seconds = date.getSeconds().toString().padStart(2, '0');
  let formattedDateTime = `${hours}:${minutes}:${seconds}`;

  return formattedDateTime
}

export function getTimeState() {
  let timeNow = new Date();
  let hours = timeNow.getHours();
  if (hours >= 6 && hours <= 10) return `morning`;
  if (hours >= 10 && hours <= 14) return `noon`;
  if (hours >= 14 && hours <= 18) return `afternoon`;
  if (hours >= 18 && hours <= 22) return `evening`;
  if (hours >= 22 && hours <= 24) return `lateEvening`;
  if (hours >= 0 && hours <= 6) return `earlyMorning`;
}