import { request } from '@/base/api/request'

const api = {
  login: '/user/login',
  logout: '/user/logout',
  getUserInfo: '/user/info',
  register: '/user/register',
  updateInfo: '/user/info/update',
  changePassword: '/user/password/change',
  switchOrg: '/user/org/switch',

  activate: '/ext/user/activate',
  forgetPassword: '/ext/user/password/forget',
  resetPassword: '/ext/user/password/reset',
  createPassword: '/ext/user/password/create',
  getVerificationCode: '/user/verificationcode/get',
  checkNotification: '/ext/user/notification/check',

  getWhiteLabel: '/ext/user/whitelabel/get',
  updateWhiteLabel: '/user/whitelabel/update',
}

export function login(parameter) {
  return request({
    url: api.login,
    data: parameter
  })
}
export function logout(parameter) {
  return request({
    url: api.logout,
    data: parameter
  })
}

export function getUserInfo(parameter) {
  return request({
    url: api.getUserInfo,
    data: parameter
  })
}

export function updateInfo(parameter) {
  return request({
    url: api.updateInfo,
    data: parameter
  })
}

export function changePassword(parameter) {
  return request({
    url: api.changePassword,
    data: parameter
  })
}

export function register(parameter) {
  return request({
    url: api.register,
    data: parameter
  })
}

export function activate(parameter) {
  return request({
    url: api.activate,
    data: parameter
  })
}


export function forgetPassword(parameter) {
  return request({
    url: api.forgetPassword,
    data: parameter
  })
}


export function resetPassword(parameter) {
  return request({
    url: api.resetPassword,
    data: parameter
  })
}

export function createPassword(parameter) {
  return request({
    url: api.createPassword,
    data: parameter
  })
}

export function checkNotification(parameter) {
  return request({
    url: api.checkNotification,
    data: parameter
  })
}

export function getVerificationCode(parameter) {
  return request({
    url: api.getVerificationCode,
    data: parameter
  })
}

export function switchOrg(parameter) {
  return request({
    url: api.switchOrg,
    data: parameter
  })
}


export function getWhiteLabel(parameter, viewData) {
  return request({
    url: api.getWhiteLabel,
    data: parameter,
    viewData,
  })
}

export function updateWhiteLabel(parameter, viewData) {
  const headers = { 'content-type': 'multipart/form-data' }
  return request({
    url: api.updateWhiteLabel,
    data:parameter,
    headers,
    viewData,
  })
}