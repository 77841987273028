import { constants, extendRouterMap } from '@/base/model/routerMap'

function getBotBuilderUrl() {
  if (import.meta.env.VITE_BOT_BUILDER_URL) {
    return import.meta.env.VITE_BOT_BUILDER_URL
  }

  let prefix = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    prefix += `:${window.location.port}`;
  }
  return `${prefix}/botbuilder`
}

const loginRouterMap = [
  {
    path: '/user',
    component: () => import('@/platform/views/Login/LoginLayout.vue'),
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/platform/views/Login/LoginForm.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/platform/views/Login/RegisterForm.vue')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import('@/platform/views/Login/RegisterResult.vue')
      },
      {
        path: 'activate/:id',
        name: 'activate',
        component: () => import('@/platform/views/Login/ActivateUser.vue')
      },
      {
        path: 'resetpassword/:id',
        name: 'resetPassword',
        component: () => import('@/platform/views/Login/ResetPasswordForm.vue')
      },
      {
        path: 'resetpassword-result',
        name: 'resetPasswordResult',
        component: () => import('@/platform/views/Login/ResetPasswordResult.vue')
      },
      {
        path: 'forgetpassword',
        name: 'forgetPassword',
        component: () => import('@/platform/views/Login/ForgetPasswordForm.vue')
      },
      {
        path: 'forgetpassword-result',
        name: 'forgetPasswordResult',
        component: () => import('@/platform/views/Login/ForgetPasswordResult.vue')
      },
      {
        path: 'accept/:id',
        name: 'acceptInvite',
        component: () => import('@/platform/views/Login/AcceptInviteForm.vue')
      },
      {
        path: 'createpassword-result',
        name: 'createPasswordResult',
        component: () => import('@/platform/views/Login/CreatePasswordResult.vue')
      },

    ]
  }
]

export const dynamicRouters = [{
  path: '/',
  component: () => import('@/base/layout/index.vue'),
  redirect: '/organization',
  children: [
    {
      path: '/organization',
      name: 'organization',
      component: () => import('@/platform/views/Org/Dashboard'),
      meta: { 'icon': 'HomeFilled', 'title': 'router.orgSetting', 'link': '', 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    },
    {
      path: '/chatbot',
      name: 'chatbot',
      component: () => import('@/platform/views/Iframe/Index.vue'),
      meta: { 'icon': 'AndroidOutlined', 'title': 'router.chatbot', 'link': getBotBuilderUrl(), 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    },
    {
      path: '/livechat',
      name: 'livechat',
      component: () => import('@/platform/views/Iframe/Index.vue'),
      meta: { 'icon': 'Service', 'title': 'router.livechat', 'link': getBotBuilderUrl(), 'hidden': true, 'full': false, 'affix': true, 'keepAlive': true },
    },
    {
      path: '/contacts',
      name: 'contact',
      component: () => import('@/platform/views/Contact/Index.vue'),
      meta: { 'icon': 'ContactsOutlined', 'title': 'router.contact', 'link': '', 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    },
    // {
    //   path: '/marketing',
    //   name: 'marketing',
    //   component: () => import('@/platform/views/Home/index.vue'),
    //   meta: { 'icon': 'Connection', 'title': 'router.marketing', 'link': '', 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    // },
    // {
    //   path: '/addon',
    //   name: 'Addon',
    //   component: () => import('@/platform/views/Home/index.vue'),
    //   meta: { 'icon': 'SetUp', 'title': 'router.addon', 'link': '', 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    // },
    // {
    //   path: '/billing',
    //   name: 'Billing',
    //   component: () => import('@/platform/views/Home/index.vue'),
    //   meta: { 'icon': 'CreditCard', 'title': 'router.billing', 'link': '', 'hidden': false, 'full': false, 'affix': true, 'keepAlive': true },
    // },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/platform/views/User/Account/Index.vue'),
      meta: { 'icon': 'UserOutlined', 'title': 'router.billing', 'link': '', 'hidden': true, 'full': false, 'affix': true, 'keepAlive': true },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('@/platform/views/User/Billing/Index.vue'),
      meta: { 'icon': 'CreditCard', 'title': 'router.billing', 'link': '', 'hidden': true, 'full': false, 'affix': true, 'keepAlive': true },
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/platform/views/Org/OrgList/Index.vue'),
      meta: { 'icon': 'CreditCard', 'title': 'router.organizations', 'link': '', 'hidden': true, 'full': false, 'affix': true, 'keepAlive': true },
    },
  ],
}]

const errorRouters = [{
  path: '/:catchAll(.*)',
  redirect: '/user/login',
}]

const setup = function () {
  constants.LOGIN_PATH = '/user/login'
  constants.NO_REDIRECT_WHITE_LIST = [...constants.NO_REDIRECT_WHITE_LIST,
    '/user/login',
    '/user/register', '/user/register-result', '/user/activate',
    '/user/resetpassword', '/user/resetpassword-result', '/user/forgetpassword', '/user/forgetpassword-result', '/user/createpassword-result']

  constants.NO_REDIRECT_SUB_PATH_LIST = ['/user/activate/', '/user/resetpassword/', '/user/accept/']


  loginRouterMap.forEach(item => {
    extendRouterMap.push(item)
  })

  dynamicRouters.forEach(item => {
    extendRouterMap.push(item)
  })

  // errorRouters.forEach(item => {
  //   extendRouterMap.push(item)
  // })
}

setup()
 