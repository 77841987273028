<template>
  <ConfigProvider>
    <RouterView />
  </ConfigProvider>
</template>

<script>
import ConfigProvider from '@/base/config/ConfigProvider.vue'

export default {
  components: {
    ConfigProvider,
  },

  data() {
    return {
      session: this.$session,
      options: this.$session.options,
    }
  },
  computed: {
  },

  created() {
  },
  mounted() {
    window.addEventListener('message', function (event) {
      if (typeof event.data != 'string') {
        return
      }
      if (event.data?.startsWith('clickOnGui')) {
        const escEvent = new KeyboardEvent('keydown', {
          key: 'Escape',
          keyCode: 27,
          code: 'Escape',
          which: 27,
          bubbles: true
        });
        const elements = document.getElementsByClassName('is-focus');
        for (let i = 0; i < elements.length; i++) {
          elements[i].dispatchEvent(escEvent)
        }
        document.dispatchEvent(escEvent);

        const clickEvent = new CustomEvent('click', {
          'detail': 'fromOtherGui'
        });
        document.body.dispatchEvent(clickEvent);
      }
    }, false);

    // 添加点击事件监听器
    document.body.addEventListener('click', function (event) {
      if (event.detail != 'fromOtherGui') {
        const elements = document.getElementsByTagName('iframe');
        for (let i = 0; i < elements.length; i++) {
          elements[i].contentWindow.postMessage('clickOnGui_platform', '*');
        }
      }
    });

  },
  watch: {
  },
  methods: {

  }
}
</script>


<style lang="less"></style>
