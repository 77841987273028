import { request } from '@/base/api/request'


const api = {
  listProductPlans: '/bill/product-plan/list',
  getProductPlan: '/bill/product-plan/get',
  addProductPlan: '/bill/product-plan/add',
  updateProductPlan: '/bill/product-plan/update',
  deleteProductPlan: '/bill/product-plan/delete',
  
  getPhoneA2pRegistrationPlan: '/bill/product-plan/phone-a2p-registration',

  listPaymentMethods: '/bill/payment-method/list',
  getPaymentMethod: '/bill/payment-method/get',
  addPaymentMethod: '/bill/payment-method/add',
  updatePaymentMethod: '/bill/payment-method/update',
  deletePaymentMethod: '/bill/payment-method/delete',
  setDefaultPaymentMethod: '/bill/payment-method/default',

  listPayments: '/bill/payment/list',

  getStripeKey: '/ext/bill/stripe/key',
  createSetupIntent: '/bill/setup-intent/create',
  changeSetupIntent: '/bill/setup-intent/change',
  cancelSetupIntent: '/bill/setup-intent/cancel',
  confirmSetupIntent: '/bill/setup-intent/confirm',

  // subscriptionBotCheck: '/bill/subscription/bot/check',
  // subscriptionBotAdd: '/bill/subscription/bot/add',
  // subscriptionBotChange: '/bill/subscription/bot/change',
  // subscriptionBotCancel: '/bill/subscription/bot/cancel',
  // subscriptionBotDelete: '/bill/subscription/bot/delete',
  // subscriptionBotResume: '/bill/subscription/bot/resume',

  listSubscription: '/bill/subscription/list',
  changeSubscription: '/bill/subscription/change',

  subscriptionPreviewProration: '/bill/subscription/proration',
}

export function listProductPlans(parameter) {
  return request({
    url: api.listProductPlans,
    data: parameter
  })
}

export function getProductPlan(parameter) {
  return request({
    url: api.getProductPlan,
    data: parameter
  })
}

export function addProductPlan(parameter) {
  return request({
    url: api.addProductPlan,
    data: parameter
  })
}

export function updateProductPlan(parameter) {
  return request({
    url: api.updateProductPlan,
    data: parameter
  })
}


export function deleteProductPlan(parameter) {
  return request({
    url: api.deleteProductPlan,
    data: parameter
  })
}

export function getPhoneA2pRegistrationPlan(parameter) {
  return request({
    url: api.getPhoneA2pRegistrationPlan,
    data: parameter
  })
}

///////////////////////////////////////
// payment method
//////////////////////////////////////
export function listPaymentMethods(parameter) {
  return request({
    url: api.listPaymentMethods,
    data: parameter
  })
}

export function getPaymentMethod(parameter) {
  return request({
    url: api.getPaymentMethod,
    data: parameter
  })
}

export function addPaymentMethod(parameter) {
  return request({
    url: api.addPaymentMethod,
    data: parameter
  })
}

export function updatePaymentMethod(parameter) {
  return request({
    url: api.updatePaymentMethod,
    data: parameter
  })
}


export function deletePaymentMethod(parameter) {
  return request({
    url: api.deletePaymentMethod,
    data: parameter
  })
}

export function setDefaultPaymentMethod(parameter) {
  return request({
    url: api.setDefaultPaymentMethod,
    data: parameter
  })
}


///////////////////////////////////////
// payment 
//////////////////////////////////////
export function listPayments(parameter) {
  return request({
    url: api.listPayments,
    data: parameter
  })
}

///////////////////////////////////////
// stripe 
//////////////////////////////////////
export function getStripeKey(parameter) {
  return request({
    url: api.getStripeKey,
    data: parameter
  })
}

export function createSetupIntent(parameter) {
  return request({
    url: api.createSetupIntent,
    data: parameter
  })
}

export function changeSetupIntent(parameter) {
  return request({
    url: api.changeSetupIntent,
    data: parameter
  })
}

export function cancelSetupIntent(parameter) {
  return request({
    url: api.cancelSetupIntent,
    data: parameter
  })
}
export function confirmSetupIntent(parameter) {
  return request({
    url: api.confirmSetupIntent,
    data: parameter
  })
}

///////////////////////////////////////
// Subscription
//////////////////////////////////////
// export function subscriptionBotCheck(parameter) {
//   return request({
//     url: api.subscriptionBotCheck,
//     data: parameter
//   })
// }

// export function subscriptionBotAdd(parameter) {
//   return request({
//     url: api.subscriptionBotAdd,
//     data: parameter
//   })
// }

// export function subscriptionBotChange(parameter) {
//   return request({
//     url: api.subscriptionBotChange,
//     data: parameter
//   })
// }

// export function subscriptionBotCancel(parameter) {
//   return request({
//     url: api.subscriptionBotCancel,
//     data: parameter
//   })
// }


// export function subscriptionBotDelete(parameter) {
//   return request({
//     url: api.subscriptionBotDelete,
//     data: parameter
//   })
// }

// export function subscriptionBotResume(parameter) {
//   return request({
//     url: api.subscriptionBotResume,
//     data: parameter
//   })
// }

export function listSubscription(parameter, viewData) {
  return request({
    url: api.listSubscription,
    data: parameter,
    viewData,
  })
}


export function changeSubscription(parameter, viewData) {
  return request({
    url: api.changeSubscription,
    data: parameter,
    viewData,
  })
}


export function subscriptionPreviewProration(parameter, viewData) {
  return request({
    url: api.subscriptionPreviewProration,
    data: parameter,
    viewData,
  })
}

