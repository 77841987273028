import { request } from '@/base/api/request'

const api = {

  listPhones: '/org/phone/list',
  getPhone: '/org/phone/get',
  addPhone: '/org/phone/add',
  updatePhone: '/org/phone/update',
  updatePhoneName: '/org/phone/update-name',
  changePlan: '/org/phone/plan/change',
  deletePhone: '/org/phone/delete',
  testPhone: '/org/phone/test',
  listAvaliablePhoneNumbers: '/org/phone/avaliable',

  getPhoneA2p: '/org/phone/a2p/get',
  registerPhoneA2p: '/org/phone/a2p/register',

  listSms: '/org/sms/list',

}


//////////////////////////////////////
// phone
//////////////////////////////////////
export function listAvaliablePhoneNumbers(parameter, viewData) {
  return request({
    url: api.listAvaliablePhoneNumbers,
    data: parameter,
    viewData,
  })
}


export function listPhones(parameter, viewData) {
  return request({
    url: api.listPhones,
    data: parameter,
    viewData,
  })
}

export function addPhone(parameter, viewData) {
  return request({
    url: api.addPhone,
    data: parameter,
    viewData,
  })
}

export function updatePhone(parameter, viewData) {
  return request({
    url: api.updatePhone,
    data: parameter,
    viewData,
  })
}


export function updatePhoneName(parameter, viewData) {
  return request({
    url: api.updatePhoneName,
    data: parameter,
    viewData,
  })
}

export function changePlan(parameter, viewData) {
  return request({
    url: api.changePlan,
    data: parameter,
    viewData,
  })
}

export function deletePhone(parameter, viewData) {
  return request({
    url: api.deletePhone,
    data: parameter,
    viewData,
  })
}

export function testPhone(parameter, viewData) {
  return request({
    url: api.testPhone,
    data: parameter,
    viewData,
  })
}

export function getPhone(parameter, viewData) {
  return request({
    url: api.getPhone,
    data: parameter,
    viewData,
  })
}

//////////////////////////////////////
// phone a2p
//////////////////////////////////////

export function registerPhoneA2p(parameter, viewData) {
  return request({
    url: api.registerPhoneA2p,
    data: parameter,
    viewData,
  })
}


export function getPhoneA2p(parameter, viewData) {
  return request({
    url: api.getPhoneA2p,
    data: parameter,
    viewData,
  })
}


//////////////////////////////////////
// phone sms
//////////////////////////////////////

export function listSms(parameter, viewData) {
  return request({
    url: api.listSms,
    data: parameter,
    viewData,
  })
}
