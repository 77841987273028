import * as header from '@/base/model/header'
import user from '@/base/model/user'

import AccountDrawer from '@/platform/views/User/Account/AccountDrawer.vue'
import BillingDrawer from '@/platform/views/User/BillingDrawer.vue'
import PreferenceDrawer from '@/platform/views/User/PreferenceDrawer'
import OrgInfoDrawer from '@/platform/views/Org/OrgInfo/OrgInfoDrawer.vue'
import OrgUserDrawer from '@/platform/views/Org/Member/OrgUserDrawer.vue'


const menuItems = [
  {
    name: 'account',
    label: 'user.menuItem.account',
    icon: 'UserOutlined',
    action: (session, biz) => { session.jumpTo('account') }
    // action: (session, biz) => { session.showAccountDrawer() }
  },
  // {
  //   name: 'organization',
  //   label: 'user.menuItem.organization',
  //   icon: 'School',
  //   action: (session, biz) => { biz.userBiz.showOrganizationDrawer() }
  // },

  {
    name: 'billing', // subscription, payment history, payment method,billing information
    label: 'user.menuItem.billing',
    icon: 'CreditCard',
    action: (session, biz) => { session.jumpTo('billing') }
    // action: (session, biz) => { biz.userBiz.showBillingDrawer() }
  },
  {
    name: 'organizations', // subscription, payment history, payment method,billing information
    label: 'user.menuItem.organizations',
    icon: 'OfficeBuilding',
    divided: true,
    action: (session, biz) => { session.jumpTo('organizations') }
    // action: (session, biz) => { biz.userBiz.showBillingDrawer() }
  },
  // {
  //   name: 'preference',
  //   label: 'user.menuItem.preference',
  //   icon: 'View',
  //   action: (session, biz) => { biz.userBiz.showApperanceDrawer(session) }
  // },
  {
    name: 'logout',
    label: 'user.menuItem.logout',
    icon: 'SwitchButton',
    divided: true,
    action: (session, biz) => { biz.userBiz.logout() }
  },
]

const components = [
  AccountDrawer,
  BillingDrawer,
  PreferenceDrawer,
  OrgInfoDrawer,
  OrgUserDrawer,
]

const setup = function () {
  menuItems.forEach(item => {
    header.avatar.menuItems.push(item)
  })

  components.forEach(item => {
    header.components.push(item)
  })
}

setup()