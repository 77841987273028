

const api = {
}

const apiPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$api = api
  }
}

export const setupApi = (app) => {
  app.use(apiPlugin)
}

export default api