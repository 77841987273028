<template>
  <iframe ref="iframe" class="frame" :src="src"></iframe>
</template>

<script>

export default {
  components: {
  },
  props: {
    src: {
      type: String
    }
  },
  directives: {
  },
  data() {
    return {
      session: this.$session,
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    window.addEventListener('message', this.handleFrameMessage);
  },
  unmounted() {
    window.removeEventListener("message", this.handleFrameMessage)
  },
  methods: {
    handleFrameMessage(event) {
     this.$emit('frameMessage', event)
    },
    reload(){
      // this.$refs.iframe.contentWindow.location.reload();
      this.$refs.iframe.src = this.$refs.iframe.src
    }
  }
}
</script>

<style scoped lang="scss">
.frame {
  width: 100%;
  height: 100%;
  // min-height: 0px;
  // min-width: 0px;
  margin: 0px;
  padding: 0px;
  background-image: none;
  background-position: 0% 0%;
  background-size: initial;
  background-attachment: scroll;
  background-origin: initial;
  background-clip: initial;
  // background-color: rgba(0, 0, 0, 0);
  // background-color:red;
  border-width: 0px;
  float: none;
  color-scheme: normal;
  inset: 0px;
  transition: none 0s ease 0s !important;
  display: block;
}
</style>
