import {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
  MoreOutlined,
  AreaChartOutlined,
  UserOutlined,
  UserAddOutlined,
  ContactsOutlined,
  UploadOutlined,
  RetweetOutlined,
  DeleteOutlined,
  AndroidOutlined,
  
} from '@ant-design/icons-vue';

const icons = {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
  MoreOutlined,
  AreaChartOutlined,
  UserOutlined,
  UserAddOutlined,
  ContactsOutlined,
  UploadOutlined,
  RetweetOutlined,
  DeleteOutlined,
  MessageOutlined,
  AndroidOutlined,
}

export const antDesignSetup = function (app) {
  for (const [key, icon] of Object.entries(icons)) {
    app.component(key, icon)
  }
}

