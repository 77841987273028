
const google = {
  googleClient: null
}

export function oauth2(clientId, oauthScope, handle, params) {
  if (!google.googleClient) {
    google.googleClient = window.google.accounts.oauth2.initCodeClient({
      client_id: clientId,
    })
  }
  google.googleClient.scope = oauthScope.join(' ')
  google.googleClient.callback = (response) => {
    params = params || {}
    params.authCode = response.code
    handle(params)
  }
  google.googleClient.requestCode()
}

export function autocomplete(input, onPlaceChange) {
  const options = {
    fields: ['name', 'formatted_address', 'address_components', 'formatted_phone_number', 'geometry', 'icon', 'opening_hours', 'place_id', 'website'],
    strictBounds: false
  }

  const autocomplete = new window.google.maps.places.Autocomplete(input, options)
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    onPlaceChange(place)
  })
  return autocomplete
}

export function convertAddress(place) {
  var addressComponents = place.address_components;
  var address = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: ''
  };

  addressComponents.forEach(component => {
    var types = component.types;
    if (types.includes('street_number')) {
      address.address1 = component.long_name + ' ' + address.address1;
    }
    if (types.includes('route')) {
      address.address1 += component.long_name;
    }
    if (types.includes('subpremise')) {
      address.address2 = component.long_name;
    }
    if (types.includes('locality')) {
      address.city = component.long_name;
    }
    if (types.includes('administrative_area_level_1')) {
      address.state = component.short_name;
    }
    if (types.includes('postal_code')) {
      address.postal_code = component.long_name;
    }
  });

  return address
}