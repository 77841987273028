import session from '@/base/model/session'
import * as billApi from '@/platform/api/billApi'

const bill = {
  stripe: null
}

bill.init = function () {
  billApi.getStripeKey({}).then((result) => {
    const publishableKey = result.body.stripe_publishable_key
    this.stripe = Stripe(publishableKey);
  }).catch((e) => {
    console.log(e)
  }).finally(() => {
  })
}

bill.init()

session.bill = bill
export default bill


